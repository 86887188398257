.searchhomemain {
  width: 100%;
  max-width: 100%;
  height: 816px;
  background: url(../../Images/searchhomebanner.png);
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 5;
  background-repeat: no-repeat;
}

.midownseacrhmaindivnew {
  width: 100%;
  max-width: 50%;
  min-width: 50%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.logedserchfrminptnew {
  width: 100%;
  max-width: 100%;
  padding-top: 30px;
  position: relative;
}

.logedserchfrminptinptnew {
  width: 100%;
  padding: 13px 40px;
  border-radius: 30px;
  box-shadow: 0px 2px 2px 0px #0000001a;
  border: 0.5px solid #00000050;
  font-size: 15px;
  color: #000;
  position: relative;
}

.logedserchfrminptinptnew:focus {
  border: 1px solid #00000050;
  outline: 0;
}

.searchiconimgnew {
  position: absolute;
  top: 41px;
  right: 27px;
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
}

.searchiconimgnew img {
  width: 100%;
  height: 100%;
}

.searchloginmain {
  width: 100%;
  height: 100%;
  padding: 72px 0px 0px 0px;
}

.bannerloginbg {
  width: 100%;
  height: 400px;
  position: relative;
}

.bannerloginbg img {
  width: 100%;
  height: 100%;
}

.bannerloginbgcontent {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-30%, -50%);
  z-index: 8;
}

.busineeheadpara {
  color: #fff;
  font-size: 50px;
  line-height: 60px;
  font-family: Roboto;
  width: 100%;
  max-width: 602px;
  font-weight: 600;
}

.busineeheadparadirecrpara {
  color: #fff;
  font-size: 58px;
  line-height: 61px;
  font-family: Roboto;
  width: 100%;
  max-width: 773px;
  font-weight: 600;
}

.businesssubparapara {
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  width: 100%;
  max-width: 707px;
  padding-top: 15px;
  line-height: 41px;
}

.choseplan {
  width: 100%;
  height: auto;
  padding: 20px 0px;
}

.radiobacustomdesign {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the tick icon */
}

.tick-green {
  border-color: green;
}

.choosplantxttpp {
  font-size: 40px;
  font-weight: 500;
  font-family: Inter;
  line-height: 43px;
  color: #000;
  text-align: center;
  margin-bottom: 4px;
}

.fromstartuptxttp {
  font-size: 26px;
  font-weight: 500;
  font-family: Inter;
  line-height: 30px;
  color: #000;
  text-align: center;
  margin-bottom: 4px;
}

.pisckktxtpp {
  color: #000;
  text-align: center;
  font-weight: 400;
  font-size: 16.5px;
  line-height: 19px;
}

.choosplantxdivmain {
  padding-top: 30px;
}

.Searchloginformmain {
  width: 100%;
  height: auto;
  padding: 85px 0px 0px 0px;
}

.Searchloginformmainbg {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.Searchloginformmainleft {
  width: 100%;
  max-width: 55%;
  min-width: 55%;
}

.Searchloginformmainleftimg {
  width: 100%;
  max-width: 100%;
  height: 680px;
}

.Searchloginformmainleftimg img {
  width: 100%;
  height: 100%;
}

.Searchloginformmainrightbg {
  padding: 50px 60px;
  width: 100%;
  max-width: 95%;
}

.creattusettxpp {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  line-height: 22px;
  font-family: Roboto;
  text-align: left;
  margin-bottom: 25px;
}

.usenametxttpp {
  font-size: 15px;
  color: #000;
  font-weight: 600;
  font-family: Roboto;
  list-style: 19px;
  margin-bottom: 6px;
}

.usenametxttppinput {
  width: 100%;
  max-width: 100%;
  border: 1px solid #b4b4b4;
  padding: 10px 15px;
  border-radius: 5px;
  color: #000;
  font-size: 15px;
  font-family: Roboto;
  position: relative;
}

.showiconnimg {
  position: absolute;
  top: 11px;
  right: 20px;
}

.usenametxttppinput:focus {
  outline: 0;
  border: 1px solid #b4b4b4;
}

.passowrdrestritiondiv {
  position: absolute;
  top: 55px;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  background-color: #d9d9d9;
  display: none;
}

.passowrdrestritiondivbottom {
  padding: 20px 20px;
}

.radionattstxtdibflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  column-gap: 10px;
}

.radiobacustomdesign {
  width: 100%;
  max-width: 15px;
  min-width: 15px;
  height: 15px;
  border: 1px solid #07ac21;
}

.radiobtntxtxtplalbel {
  font: 400 15px/18px Roboto;
  color: #000000;
  margin-bottom: 0px;
}

.passowrdrestritiondivtop {
  width: 100%;
  background-color: #07ac21;
  position: relative;
  padding: 20px 20px;
}

.passowrdrestritiondivtop::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 9px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #07ac21;
  top: -21px;
  left: 9px;
}

.minimumpasswodttrxpp {
  font: 400 14px/18px Roboto;
  color: #fff;
}

.usenametxttppmian {
  width: 100%;
  /* max-width: 98%; */
  margin-bottom: 10px;
  /* position: relative; */
  max-width: 80%;
}

.usenametpasswrdifv {
  position: relative;
}

.usenametpasswrdifv:hover .passowrdrestritiondiv {
  display: block;
}

.Searchloginformmainright {
  width: 100%;
  max-width: 45%;
  min-width: 45%;
}

.agereecheck {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 10px;
  padding-bottom: 25px;
}

.schekbdttstxttpp {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  height: 15px;
}

.agreerxtppptt {
  font-size: 15px;
  color: #000;
  font-weight: 500;
  font-family: Roboto;
  line-height: 19px;

  width: 100%;
}

.agreerxtppptt span {
  color: #158ce3;
}

.addedasldibv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 71px;
}

.addesdwqtssxtoipnt {
  width: 100%;
  max-width: 90px;
  min-width: 90px;
  height: 40px;
  border: 1px solid #b4b4b4;
  padding: 10px 15px;
  border-radius: 5px;
}

.addersrquesttsxtpp {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  font-family: Roboto;
  line-height: 18px;
  margin-right: 20px;
}

.btnnexttdivbtn {
  background-color: #09a72c;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 6px 30px;
  border: none;
  border-radius: 5px;
}

.btnnexttdivbtnmain {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 71px;
  padding-top: 20px;
}

.listbusinessformdivmain {
  width: 100%;
  height: auto;
  padding: 72px 0px 0px 0px;
}

.listbusinessdivflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.listbusinessdivleft {
  width: 100%;
  max-width: 70%;
}

.listbusinessdivrightt {
  width: 100%;
  max-width: 30%;
}

.listbusinessdivleftbg {
  width: 100%;
  background-color: #fff;
  padding: 30px 35px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.listbusinesstxtpp {
  color: #000;
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
}

.error {
  color: red;
  font-size: 16px;
}

.conattaindptxtpp {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  font-family: Roboto;
  margin-bottom: 20px;
}

.contatcindofdicfplx {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
}

.contatcindofdiinpur {
  width: 100%;
  max-width: 48%;
  min-width: 48%;
  padding-bottom: 20px;
}

.contatcindofdiinpurtxtp {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  font-family: Roboto;
  line-height: 22px;
  padding-bottom: 15px;
}

.contatcindofdiinpurnewalasda {
  width: 100%;
  max-width: 60%;
}

.inpustxocntbusuitxtpp {
  width: 100%;
  max-width: 100%;
  padding: 14px 22px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.dropwdowndivfrmbusinee {
  width: 100%;
  max-width: 100%;
  padding: 18px 22px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  position: relative;
}

.vectoraarowdivomg {
  position: absolute;
  top: 14px;
  right: 23px;
  width: 100%;
  max-width: 18px;
  min-width: 18px;
  height: 18px;
}

.vectoraarowdivomg img {
  width: 100%;
  height: 100%;
}

.dropwdowndivfrmbusinee:focus {
  outline: 0;
  border: 1px solid #b4b4b4;
}

.inpustxocntbusuitxtpp:focus {
  outline: 0;
  border: 1px solid #b4b4b4;
}

.vectoraarowdivomgulmai {
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px #ccc;
  width: 100%;
  max-width: 80%;
  min-width: max-content;
  padding: 20px;
  border-radius: 5px;
  position: absolute;
  top: 45px;
  left: 24px;
  z-index: 1;
  height: 300px;
  overflow-y: auto;
}

.vectoraarowdivomgulmaiul {
  list-style-type: none;
}

.vectoraarowdivomgulmaili {
  /* color: #9e9797; */
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto;
  line-height: 20px;
  margin-bottom: 9px;
  cursor: pointer;
}

.vectoraarowdivomgulmaili:last-child {
  margin-bottom: 0px;
}

.btnnexttdivbtndivmain {
  display: flex;
  justify-content: flex-end;
}

.contatcindofdiinptextareediv {
  width: 100%;
  max-width: 100%;
}

.inpustxocntbusuitxtaretpp {
  width: 100%;
  max-width: 100%;
  padding: 20px 22px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  height: 90px;
  resize: none;
}

.worrsrrttxtpphu {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  color: #b4b4b4;
  font-weight: 500;
}

.icopndibimahhmainimjh {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
}

.icopndibimahhmainimjh img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.icopndibimahhmainimjhdibmai {
  width: 100%;
  max-width: 100%;
  padding: 14px 22px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.faccevttxttpphhtxtpp {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.ownblmprofiledivmaoin {
  width: 100%;
  height: auto;
  padding: 85px 0px 0px 0px;
}

.otherblmprofiledivmaoin {
  width: 100%;
  height: auto;
  padding: 85px 0px 0px 0px;
}

.ownblmprofiledivflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 10px;
}

.likedfollowotherdivflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 27px 25px 27px;
}

.ownblmprofiledivleft {
  width: 100%;
  max-width: 30%;
  min-width: 30%;
}

.ownblmprofiledivright {
  width: 100%;
  max-width: 69%;
  max-width: 69%;
}

.editprofileblm {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px;
}

.editeiconimg {
  width: 100%;
  /* max-width: 20px;
  min-width: 20px;
  height: 20px; */
  max-width: 28px;
  min-width: 28px;
  height: 28px;
  margin-left: auto;
}

.editeiconimg img {
  width: 100%;
  height: 100%;
}

.editprofile {
  width: 100%;
  max-width: 140px;
  min-width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: #eaeaea;
  margin: auto;
  margin-top: 15px;
  position: relative;
  /* cursor: pointer; */
}

.bvhhjjapsidhbfbbrkjk:hover {
  color: #fff;
}

.avoiaresrrstrtxppp {
  font: 300 15px/20px Roboto;
  color: #000;
  margin-bottom: 15px;
}

.prPremiumofiletxtp {
  font: 400 20px/25px Roboto;
  color: #000;
  margin-bottom: 15px;
}

.boxosimaggedisahdowdiv {
  width: 100%;
  height: 400px;
  padding: 30px;
  position: relative;
  background-color: #d9d9d9;
}

.tsttsggsiijxbhnsimag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: 400 15px/20px Roboto;
  color: #000;
}

.cameralogodivimg {
  width: 100%;
  max-width: 42px;
  min-width: 41px;
  height: 35px;
  margin: auto;
  margin-bottom: 10px;
}
.plan_padding{
  padding: 0px !important;
}
.plan_border{
  border-radius: 8px;
  padding: 30px 20px !important;
}
.cameralogodivimg img {
  width: 100%;
  height: 100%;
}

.uplsdttxttppshji {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  text-align: center;
}

.pixelsttxttppio {
  font-size: 12px;
  color: #868686;
  font-weight: 400;
  text-align: center;
}

.cameralogodivimgabslt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 18px;
  /* z-index: 6; */
}

.ocensttxttpppp {
  font-size: 24px;
  color: #000;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 4px;
  margin-top: 15px;
  text-align: center;
}

.adverttstyusuppp {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  line-height: 17px;
  margin-top: 8px;
  text-align: center;
}

.likesfolwoerdivfxls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 60px;
}

.likstrtxttpp {
  font-size: 15px;
  color: #000;
  font-weight: 400;
  line-height: 18px;
}

.likedfollowdivflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 27px 10px 27px;
}

.likedfollowlike {
  width: 100%;
  /* max-width: 83px; */
  max-width: fit-content;
  min-width: 83px;
  height: 40px;
  border: 1px solid #e1caff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  font-size: 17px;
  color: #000000;
  column-gap: 8px;
}

.likedfollowlike img {
  width: 100%;
  height: 100%;
  max-width: 21px;
  min-width: 20px;
  height: 20px;
}

.foolowndnbyhbnbtn {
  width: 100%;
  max-width: 122px;
  min-width: 122px;
  padding: 6px 14px;
  border-radius: 50px;
  color: #fff;
  font-size: 18px;
  background-color: #662d91;
  border: none;
}

.starrating {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 30px 15px;
}

.star-svg {
  margin-left: 10px;
}

.bmlmmaposttaddddib {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px;
}

.bmlmmaposttaddddibdivmain {
  padding: 20px;
}

.poisyybtnmain {
  background-color: #09984b;
  border: none;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  line-height: 18px;
  padding: 11px 30px;
  border-radius: 17px;
}

.poisyybtnmain i {
  margin-left: 10px;
  font-size: 17px;
}

.poiosyyattatxtpp {
  font-size: 15px;
  color: #000;
  line-height: 18px;
  width: 100%;
  max-width: 226px;
  min-width: 226px;
  padding-bottom: 20px;
}

.viewallpostlnk {
  font: 400 16px/20px Roboto;
  color: #000;
}

.poisyybtnmainlibkldivflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewsandebgagemntdiv {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px;
}

.viewsandebgagemntdivmain {
  padding: 20px;
}

.tavbedebieisdtldiv {
  width: 100%;
  max-width: 80%;
  min-width: 80%;
}

.othertaptoratemain {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px;
}

.starratingothedifiub {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 3px 30px 5px;
}

.ratingffsgivebtnimgnain {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
}

.ratingffsgivebtnimgnain img {
  width: 100%;
  height: 100%;
}

.star-svg:first-child {
  margin-left: 0px;
}

.starratingothedifiub .star-svg {
  margin-left: 6px;
}

.starratingothedifiub .star-svg:first-child {
  margin-left: 0px;
}

.ratingffsgivebtn {
  border: 1px solid #ab4ad9;
  padding: 8px 9px;
  color: #000;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-family: Roboto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}

.starratingbratdivflxsgivebtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 25px;
}

.tavbedebieisdtldivtrd {
  color: #000;
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
  font-family: Roboto;
  padding-bottom: 15px;
  margin: 100%;
  max-width: fit-content;
  min-width: fit-content;
}

.vuisrrcannsiittxtp {
  color: #4074fa;
  font-size: 17px;
  font-weight: 500;
  font-family: Roboto;
}

.vuisrrcannsiittxtpmaindib {
  text-align: right;
}

.sendenquesrtydivmain {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px;
}

.sendwieidjnndnmainbbth {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
}

.enquersrtsimgagmmubnnmgbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  border: none;
  background-color: #d9d9d9;
  padding: 11px 17px;
  color: #434242;
  font: 400 19px / 21px Roboto;
  border-radius: 10px;
}

.socisllakijsnnlinkdivmain {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px;
}

.socilaimnnkotherdetsdivdivbmain {
  padding: 10px 30px 10px 30px;
}

.soiiajujshdijhdivfllsklodj {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 10px;
  padding: 15px 0px 0px 0px;
}

.faccevttxttpphhtxtppnewoiothtrr {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-weight: 600;
}

.icopndibimahhmainimjhmewother {
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
}

.icopndibimahhmainimjhmewother img {
  width: 100%;
  height: 100%;
}

.enquersrtsimgagmmub {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
}

.enquersrtsimgagmmub img {
  width: 100%;
  height: 100%;
}

.meettscysttxtpp {
  font: 500 12px / 15px Roboto;
  color: #000;
  margin-bottom: 5px;
  min-width: fit-content;
}

.wehelpsppeottxpp {
  font: 400 10px / 18px Roboto;
  color: #000;
  margin-bottom: 30px;
}

.trtysttxnowttxtp {
  border: none;
  background-color: transparent;
  color: #000;
  font-size: 14px;
  font-weight: 300;
  font-family: Roboto;
}

.viewsandebgagemntdpaddinf {
  padding: 20px 16px;
}

.ipyysffraedtn {
  background-color: #089f29;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: Roboto;
  border: none;
  border-radius: 5px;
  padding: 6px 25px;
}

.trtysttxnowtrtysttxndiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
}

.contactdetsdiv {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  /* padding: 10px; */
}

.contactdetsdivdivbmain {
  padding: 30px 30px 15px 30px;
  border-bottom: 2px solid #d9d9d9;
}

.contactdetsdivdivbmaindivbtm {
  padding: 15px 30px 15px 30px;
}

.consttactdetatlsarroedyrdiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cinnattddttdtsttspo {
  font: 500 20px/24px Roboto;
  color: #000;
}

.indfodetatildivmainui {
  padding-top: 20px;
}

.indfodetatildiv {
  width: 100%;
  max-width: 19px;
  min-width: 19px;
  height: 26px;
}

.indfodetatildiv img {
  /* width: 100%;
  height: 100%; */
  max-width: 20px;
}

.ingfddtertrrxrpp {
  font: 400 14px / 18px Roboto;
  color: #585454;
}

.infodetatddivfllsxdlx {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 20px;
  padding-bottom: 15px;
}

.infodetatddivfllsxdlx:last-child {
  padding-bottom: 0px;
}

.direxrrcttstbtn {
  background-color: #dedede;
  border: none;
  color: #000000;
  font-size: 15px;
  border: none;
  padding: 6px 20px;
  border-radius: 8px;
  font-weight: 500;
}

.direxrrcttstbtn img {
  width: 100%;
  max-width: 15px;
  min-width: 15px;
  height: 15px;
  margin-left: 10px;
}

.direxrrcttstbtnmain {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px 20px 0px;
}

.oenblmsnfdrpbapbbtm {
  padding-bottom: 10px;
}

.uploadbannerblmdiv {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px;
}

.uploadbannerdivbg {
  background-color: #eaeaea;
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 6px;
  position: relative;
  height: 283px;
}

.uploadbannerdivnewmain {
  width: 100%;
  padding: 20px;
}

.abttsbttetxtttpp {
  font: 400 12px / 22px Roboto;
  color: #000;
  margin: 0px 0px 15px 0px;
  width: 100%;
  max-width: 630px;
  min-width: 630px;
}

.uploadimagedivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  margin: 40px 0px 20px 0px;
}

.abttsrtxtdivvnappadin {
  padding: 30px 0px 15px 0px;
}

.uploadimagebg {
  width: 100%;
  max-width: 23%;
  min-width: 23%;
}

.uploadimagebgmain {
  width: 100%;
  max-width: 100%;
  height: 160px;
  background-color: #d9d9d9;
  border-radius: 8px;
  position: relative;
}

.editeiconimghideen i {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 8;
  display: none;
}

.editeiconimghideenalda i {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 8;
  display: block;
}

.imagetitrtlstrtrxpp {
  font: 400 16px/19px Roboto;
  text-align: center;
  color: #000;
  margin: 10px 0px;
}

.fallsebblldivmain {
  width: 100%;
  min-width: 32%;
  max-width: 32%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.fallsebblldivmaindivflxl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
  padding: 38px 0px 20px 0px;
}

.adversytfservicexedib {
  padding: 20px 0px 30px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.servicdesdetatcxttxpp {
  font: 400 18px / 22px Roboto;
  color: #000;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.servicdesdetatcxttxpp::after {
  position: absolute;
  content: "|";
  top: 0px;
  left: auto;
  right: auto;
  /* left: auto; */
  margin-left: 6px;
  margin-right: 6px;
  font-size: 19px;
  font-weight: 600;
}

.servicdesdetatcxttxpp:last-child::after {
  position: absolute;
  content: "";
  top: 0px;
  left: auto;
  margin-left: 3px;
  font-size: 19px;
  font-weight: 600;
}

.servicdesdetatcxttxpp:first-child {
  margin: 0px;
}

.chooseplandivmaon {
  padding: 50px 50px;
}

.allpostmyapooskducvsdic {
  width: 100%;
  background-color: #fff;
}

.allpostmyapooskducvsdictop {
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px solid #959292;
}

.postamtydposdivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}

.poisttsdpostdibbtn {
  padding: 6px 20px;
  background-color: transparent;
  color: #000;
  font: 500 15px/20px Roboto;
  border: none;
  border-radius: 5px;
}

.poisttsdpostdibbtn.actv {
  padding: 6px 20px;
  background-color: #d9d9d9;
  color: #000;
  font: 500 15px/20px Roboto;
  border: none;
  border-radius: 5px;
}

.allpostmyapooskducvsdicbottom {
  /* padding: 20px 30px ; */
  width: 100%;
}

.chooseplandivmaondivflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 20px;
}

.chooseplandivmaondivbrdr {
  width: 100%;
  max-width: 32%;
  min-width: 32%;
}

.chooseplandivmaondivbrdrmain {
  width: 100%;
  max-width: 100%;
  border: 1px solid #034f75;
  /* padding: 25px 20px; */
  padding: 25px 0px;
  border-radius: 5px;
}

.basiuicvaytbtu {
  width: 100%;
  max-width: 140px;
  min-width: 140px;
  padding: 6px 16px;
  color: #000;
  border: 1px solid #034f75;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: transparent;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  height: 41px;
}

.freesttxppp {
  text-align: center;
  color: #070707;
  /* font: 500 28px / 32px Roboto; */
  font: 500 18px / 22px Roboto;
  margin: 10px 0px 6px 0px;
}

.auuhshtxttpp {
  text-align: center;
  /* color: #989898; */
  /* font: 500 17px/22px Roboto; */
  margin-bottom: 15px;
  font: 500 20px/24px Roboto;
}
.product_services_div_wrapper{
  padding-bottom: 20px;
  border-radius: 8px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 10px;
}
.renew_para{
  font-family: Roboto;
font-size: 14px;
font-weight: 400;
padding-right: 30px;
margin-top: 10px;
}
.renew_para a {
  color:#000;
}
.renew_para a:hover {
  text-decoration: underline;
}
.bg_white{
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
}
.dear_client_message_div p {
  font-family: Roboto;
font-size: 13px;
font-weight: 400;
margin-top: 5px;
}
.client_help_div p {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
}
.client_help_div{
  margin-top: 20px;
}
.baysyysttxtppp {
  color: #000000;
  font: 500 20px/24px Roboto;
  margin-bottom: 10px;
  margin-left: 20px;
}
.product_head_div{
  display: flex;
  justify-content: space-between;
  background-color: rgb(238, 238, 238);
  width: 100%;
  padding: 20px 60px;
}
.product_head_div2{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 60px;
}
.product_head_div h2{
  font-family: Roboto;
  /* font-size: 20px; */
  font-size: 18px;
  font-weight: 500;
  width: 20%;
}
.product_head_div2 h2{
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  width: 20%;
}
.product_head_div2 span{
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
}
.baysyysttxtpppdsidnbmai {
  padding: 20px 20px;
}

.busuyyasbasytxtxtppul {
  list-style-type: none;
  padding: 0px 20px
}

.choosttappanbtn {
  width: 100%;
  max-width: 209px;
  min-width: 209px;
  padding: 6px 16px;
  color: #000;
  border: none;
  background-color: #d3e7f0;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  height: 43px;
}

.vieiimitrrebtn {
  color: #656565;
  font: 400 17px / 20px Roboto;
}

.vieiimitrrebtn:active {
  color: #656565;
  font: 400 17px / 20px Roboto;
}

.vieiimitrrebtn:hover {
  color: #656565;
  font: 400 17px / 20px Roboto;
}

.Viewbtnmain {
  padding: 25px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.busuyyasbasytxtxtppli {
  font: 400 15px / 20px Roboto;
  color: #050505;
  position: relative;
  padding-inline-start: 34px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.busuyyasbasytxtxtppli::before {
  position: absolute;
  top: 8px;
  left: 0px;
  content: url(../../Images/correxttcticon.png);
}

.btnnexttdivbtn:hover {
  color: #fff;
}

.profileimhaggemaindib {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
}

.editeiconimgspclnmew {
  width: 100%;
  max-width: 28px;
  min-width: 28px;
  height: 28px;
  margin-left: auto;
  position: absolute;
  top: 8px;
  right: 17px;
  z-index: 6;
}

.editeiconimgspclnmew img {
  width: 100%;
  height: 100%;
}

.upoloafgsbabberfibommjge {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

.upoloafgsbabberfibommjge img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.gagelttysimgdivmain {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

.gagelttysimgdivmain img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 10px;
}

.profileimhaggemaindib img {
  width: 100%;
  height: 100%;
}

.allpostowndmaimndiv {
  width: 100%;
  padding: 20px 40px;
  border-bottom: 1px solid #959292;
}

.allpostmyapooskducvsdicbottom .allpostowndmaimndiv:last-child {
  border-bottom: 0px solid #959292;
}

.lodmrebtnmainndivmaindib {
  width: 100%;
  max-width: 70%;
  margin: auto;
}

.allpostowndmaimndivallelemnt {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.allpostimagedesuigfnatinob {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  column-gap: 10px;
}

.allposttslodjjimg {
  width: 100%;
  max-width: 60px;
  min-width: 60px;
  height: 60px;
}

.allposttslodjjimg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.neeporoalposttsheadr {
  font: 500 17px / 20px Roboto;
  color: #1d1d1d;
  margin-bottom: 5px;
}

.cmskshhstdiifgysyttxtpop {
  font: 400 15px/20px Roboto;
  color: #1d1d1d;
  margin-bottom: 5px;
}

.crosasddoproidikdsjbhidivflx {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  column-gap: 20px;
}

.crossidovpostimgdiv {
  width: 100%;
  max-width: 13px;
  min-width: 13px;
  height: 13px;
}

.crossidovpostimgdiv img {
  width: 100%;
  height: 100%;
}

.cmskshhstdiifgysyttxtpop span {
  font: 400 15px/20px Roboto;
  color: #1d1d1d;
  margin-bottom: 5px;
}

.postbannettimhdivflxmakflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
  margin: 20px 0px 5px 0px;
}

.allpostlikecommetsendenqeryudiv {
  padding-top: 10px;
}

.postbanneimmhgdivjakhjimh {
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  height: 131px;
}

.postbanneimmhgdivjakhjimh img {
  width: 100%;
  height: 100%;
}

.htrrstrtdttgsgbtxttppgh {
  text-align: right;
  font: 300 14px/19px Roboto;
  color: #928f8f;
}

.poosttalouindtdettstctpopdoiv {
  width: 100%;
  max-width: 60%;
  min-width: 60%;
}

.threediostyiicpn i {
  font-size: 20px;
  color: #565454;
  margin-top: 6px;
  position: relative;
}

.threediostyiicpnggsuc {
  position: relative;
}

.threedotssettingdiv {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  background-color: #fff;
  width: 200px;
  padding: 5px 10px;
  box-shadow: 0px 0px 5px 0px #9d9a9a;
  z-index: 25;
  border-radius: 4px;
}

.postimagedesrcptoioponttxp {
  font: 400 15px/20px Roboto;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 4; */
  margin-bottom: 5px;
}

.responsivdedivmain {
  display: none;
}

.ownseacrhresultpagemain {
  width: 100%;
  height: auto;
  padding: 85px 0px 0px 0px;
}

.ownseacrgresultpagedivbg {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 20px;
  margin-top: 15px;
}

.ownseacrgresultpagedivflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 28px;
}

.serchresutllogodetaildivflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 15px;
}

.serachresultlogoidiv {
  width: 100%;
  max-width: 60px;
  min-width: 60px;
  height: 59px;
}

.serachresultdetailsdiv {
  width: 100%;
  max-width: 70%;
  min-width: 70%;
}

.serachresultlogoidiv img {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  border-radius: 50%;
}

.serctedheadrparatxt {
  font: 400 21px / 25px Roboto;
  color: #072eba;
}

.dofleoodiistartdivflx {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.statrrdibvggshhghuju i {
  color: #fbb03b;
  margin-left: 4px;
  font-size: 13px;
}

.serchrelutparatxtmm {
  font: 400 15px/20px Roboto;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 1; */
  margin-bottom: 2px;
}

.serchrelusubtparatxtmm {
  font: 400 12px / 16px Roboto;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 3; */
}

.ownseacrgresultpageleft {
  width: 100%;
  max-width: 55%;
  min-width: 55%;
}

.ownseacrgresultpageright {
  width: 100%;
  max-width: 38%;
  min-width: 38%;
}

.searchhresultandfilterdivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}

.searfchresultleftdiv {
  width: 100%;
  max-width: 70%;
  min-width: 70%;
  position: relative;
}

.searfchresultleftdivmain {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: 1px solid #969596;
  border-radius: 50px;
  padding: 10px 50px 10px 10px;
  position: relative;
}

.searfchresultleftdivmain:focus {
  outline: 0;
  border: 1px solid #969596;
}

.sectgresulticon {
  position: absolute;
  top: 7px;
  right: 20px;
  cursor: pointer;
  width: 100%;
  max-width: 22px;
  min-width: 22px;
  height: 22px;
}

.serchresutllogodetaildivflx {
  margin-bottom: 20px;
  padding: 10px;
  transition: 0.5s all ease-in-out;
  border-radius: 10px;
  cursor: pointer;
}

.serchresutllogodetaildivflx:hover {
  background-color: #f5b0e26b;
  transition: 0.5s all ease-in-out;
  border-radius: 10px;
}

.sectgresulticon img {
  width: 100%;
  height: 100%;
}

.searchfilterdiv {
  width: 100%;
  max-width: 5%;
  min-width: 5%;
}

.ownseacrgresultpagerightbrdr {
  width: 100%;
  border: 1px solid #a4a4a45c;
  border-radius: 10px;
}

.ownseacrgresultpagerightbrdrtop {
  width: 100%;
  border-bottom: 1px solid #dedede;
}

.ownseacrgresultpagerightbrdrtopbottom {
  width: 100%;
  padding: 15px 20px 15px 87px;
}

.tttxtyaplattsyggjjkllkkk {
  font: 300 12px / 20px Roboto;
  color: #000;
}

.ownpostothercommentdivmain {
  width: 100%;
  max-width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
  padding-top: 10px;
}

.phokennsjjsnyunnrbtk {
  font: 400 16px/20px Roboto;
  color: #000;
  margin: 20px 0px 10px 0px;
}

.serttxtghsytxoppjkjo {
  font: 400 14px/18px Roboto;
  color: #072eba;
}

.ownseacrgresultpagerreslppimg {
  width: 100%;
  height: 160px;
}

.opperpoodshheyxgbpppn {
  font: 300 12px / 15px Roboto;
  color: #000;
  margin-top: 20px;
  margin-bottom: 8px;
}

.diisjadfebnclkmdgragjksopjkl {
  font: 400 14px/20px Roboto;
  color: #000;
}

.ownseacrgresultpagerreslppimg img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.searchfilterdivmain {
  width: 100%;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  padding: 7px;
  background-color: #fff;
  border-radius: 5px;
}

.sercghghfilyerimg {
  width: 100%;
  max-width: 100%;
  height: 27px;
}

.logostretdijjkkkljujuo {
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  margin-top: -12px;
}

.viewprofileimgaedivvhjjnflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  column-gap: 20px;
  margin-bottom: 20px;
}

.logostretdijjkkkljujuo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sclloidjjdttxppphjo {
  font: 300 14px/18px Roboto;
  color: #000;
  margin-bottom: 10px;
}

.prijjdhjjjjkjaaimg {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
}

.prijjdhjjjjkjaaimg img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 50%;
}

.bvhhjjapsidhbfbbrkjk {
  background-color: #009e60;
  color: #fff;
  font: 400 14px/18px Roboto;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
}

.logostrdetyskksldovkkdpo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 10px;
  /* padding: 20px; */
  padding: 0px 12px;
}

.neosertsttfzrrtty {
  font: 500 20px/24px Roboto;
  color: #000;
}

.neosertsttfzrrttyadfd {
  font: 300 15px/25px Roboto;
  color: #000000;
}

.socijjjsjkjijnndnk {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  display: block;
}

.socijjjsjkjijnndnk img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 50%;
}

.socijjjsjkjijnndnkdobvbbhflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 20px;
}

.allttehdiioskjetyapasedoib {
  width: 100%;
  max-width: 80%;
  min-width: 80%;
  padding-top: 5px;
}

.sercghghfilyerimg img {
  width: 100%;
  height: 100%;
}

.ownpostcommentdiv {
  width: 100%;
  max-width: 100%;
}

.ownpostcommentdivmain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 20px;
  padding: 20px 0px;
}

.prodctlikecmntenquerypagediv {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 10px 0px;
}

.cntnctimgdivnamin {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
}

.cntnctimgdivnamin img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ownpostcommentdivmain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 20px;
}

.textareadibmain {
  width: 100%;
}

.textareadibmainrerare {
  width: 100%;
  height: 80px;
  overflow-y: auto;
  word-break: break-word;
  white-space: pre-wrap;
  font: 400 14px / 18px Roboto;
  color: #000;
  padding: 10px;
  resize: none;
  border-radius: 10px;
  /* border: 1px solid #ccc; */
  border: none;
}

.textareadibmainrerare:focus {
  outline: 0;
  border: none;
}

.textareadibmainmainbrdr {
  width: 100%;
  height: 117px;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
}

.mesagsendidvmain {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 21px;
  padding-bottom: 10px;
}

.mesagsendidvmain i {
  font-size: 20px;
  color: #662d91;
}

.likesfolwoermobilrres {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.textareadibmainrerare::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.textareadibmainrerare {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.freebusinessbtn {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font: 400 15px/18px Roboto;
  background-color: transparent;
  color: #fff;
}

.freebusinessbtn:hover {
  background-color: #fff;
  color: #000;
}

.freebusinessbtndibmaoin {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 20px 0px;
}

.businedirreidovmain {
  padding: 40px 185px 40px 100px;
}

.businedirreidovmainttxpp {
  font: 300 25px / 30px Roboto;
  color: #000;
  width: 100%;
}

.businedirreidovmainnedpoing {
  border-top: 1px solid #cccccc;
  padding: 30px 100px;
}

.businedirreidovmainnedpoingdibvlcx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 80px;
}

.mobilecopydivimage {
  width: 100%;
  height: 406px;
  max-width: 220px;
  min-width: 220px;
}

.mobilecopydivimage img {
  width: 100%;
  height: 100%;
}

.businedirreidovmainnedpoingdiright {
  width: 100%;
  max-width: 35%;
  min-width: 35%;
  padding-top: 30px;
}

.buiisconnesttxtppp {
  font: 500 18px/22px Roboto;
  color: #000;
}

.sennjandrecetxtpp {
  font: 400 16px/18px Roboto;
  color: #000;
}

.businedirreidovmainnedpoingdimain {
  padding-bottom: 33px;
}

.businesssubparaparasecrtstt {
  color: #fff;
  font-size: 28px;
  font-weight: 300;
  width: 100%;
  max-width: 757px;
  padding-top: 15px;
}

.bg-whtdivnmanb {
  width: 100%;
  background-color: rgba(250, 250, 250, 1);
  height: 100%;
  margin-top: 10px;
  padding: 30px 50px;
}

.makednekleosearchtxtpp {
  font: 500 36px / 43px Roboto;
  color: #000;
  width: 100%;
  max-width: 460px;
  margin: auto;
  text-align: center;
}

.WhatmakesNeoosearchtxtp {
  font: 400 22px/30px Roboto;
  color: #000;
  width: 100%;
  max-width: 860px;
  margin: auto;
  text-align: center;
  padding: 20px 0px;
}

.bg-whtdivnmanbdivmaidfflex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 110px;
  flex-wrap: wrap;
}

.bg-whtdivnmanbdivmaimdivfexrt {
  width: 100%;
  max-width: 42%;
  min-width: 42%;
  padding-bottom: 25px;
}

.rewarddibbabimgahsdimg {
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
  margin: auto;
  margin-bottom: 10px;
}

.rewarddibbabimgahsdimg img {
  width: 100%;
  height: 100%;
}

.Promotetxtpp {
  font: 500 24px / 28px Roboto;
  color: #000;
  margin-bottom: 22px;
  text-align: center;
}

.freetetxtppp {
  font: 400 16px/20px Roboto;
  color: #000;
  text-align: center;
}

.bg-whtdivnmanbdivmaimaindiv {
  padding: 20px 100px;
}

.membermastersection {
  width: 100%;
  height: auto;
  padding: 85px 0px 0px 0px;
}

.membermastersectionbg {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
}

.membermastersectionbgtop {
  width: 100%;
  padding: 30px 40px;
  border-bottom: 1px solid #dbd8d8;
}

.membermastersectionbgbottom {
  width: 100%;
  padding: 40px 40px;
}
.page_status_div_main{
  margin-top: 40px;
}
.page_status_para{
  font-family: Roboto;
  font-size: 17px;
  margin-left: 20px;
}
.page_status_details{
  border-radius: 8px;
  background-color: rgb(238, 238, 238);
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
}
.page_name_profile_wrapper_div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}
.page_live_status_div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}
.live_para{
  font-family: Roboto;
font-size: 20px;
}
.live_green_div{
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(34, 148, 16);
  display:flex ;
  justify-content: center;
  align-items: center;
}
.live_green_div2{
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  display:flex ;
  justify-content: center;
  align-items: center;
}
.live_green_div img{
  width: 100%;
  max-width: 50%;
  min-width: 50%;
}
.live_green_div2 img{
  width: 100%;
  
}
.create_profile_btn{
  width: fit-content;
  padding: 13px 27px;
  background-color: rgb(238, 238, 238);
  font-family: Roboto;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  text-decoration: none;
}
a.create_profile_btn {
  color:#000;
}
.page_edit_delete_wrapper{
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 30px;
  padding: 15px 40px;
}
.border_div{
  border-top: 1px solid rgba(186, 181, 181, 1);
  margin-top: 30px;
}
.page_owner_name{
  font-family: Roboto;
font-size: 20px;
font-weight: 500;

}
.page_action_para{
  font-family: Roboto;
font-size: 13px;
font-weight: 400;
}
.page_action_para a {
  color: #000;
}
.page_action_para a:hover {
  text-decoration: underline;
}
.page_name{
  font-family: Roboto;
font-size: 20px;
font-weight: 500;
}
.page_name2{
  font-family: Roboto;
font-size: 16px;
font-weight: normal;
}
.page_profile_img{
  width: 100%;
  max-width: 75px;
  min-width: 75px;
  height: 75px;
  border-radius: 50%;
}
.page_profile_img img{
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
}
.membermastersectionbgbottomdivflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 30px;
  flex-wrap: wrap;
  row-gap: 20px;
}

.embermastersectionbgbobgmai {
  width: 100%;
  max-width: 31%;
  min-width: 31%;
}

.embermastersectionbgbobgmainnbg {
  width: 100%;
  padding: 40px 41px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 25px;
}

.analyticsshsimage {
  width: 100%;
  max-width: 43px;
  min-width: 43px;
  height: 43px;
}

.analyticsshsimage img {
  width: 100%;
  height: 100%;
}

.alkantsrepposttxtpp {
  font: 400 20px / 24px Roboto;
  color: #000;
}

.alkantsrepposttxtppqueiytettcpp {
  font: 400 21px / 24px Roboto;
  color: #0b0b0b82;
}

.dashbordimgdivimg {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
}

.dashbordimgdivimg img {
  width: 100%;
  height: 100%;
}

.dahshnlisjhtxtpp {
  font: 500 20px/25px Roboto;
  color: #000;
}

.dashbordimgdtioodicflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 10px;
}

.mobilereposiveprofile {
  width: 100%;
  max-width: 100%;
  display: none;
}

.reposivecideforspecial {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 10px;
}

.workprogressdivsection {
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 10px;
}

.workprogressdivalldatadiv {
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  max-width: 75%;
}

.workprogressdivmain {
  height: 2px;
  width: 65%;
  position: absolute;
  top: 22px;
  left: 11.5%;
}
.viewsyatpaynowewdivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  max-width: 24%;
  min-width: 24%;
}
.viewStatusdivbtnb {
  background-color: transparent;
  color: #000;
  border: none;
  font: 400 16px/18px Roboto;
  padding: 6px 15px;
  border-radius: 5px;
}
.workprogressdivdivmnaindlx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paynowbtnb {
  background-color: #02cc52;
  color: #fff;
  border: none;
  font: 400 16px/18px Roboto;
  padding: 6px 15px;
  border-radius: 5px;
}
.workprogressdivcriclemain {
  display: block;
  flex: unset;
  margin: 0 0 10px;
  width: 22%;
  border-radius: 0 !important;
  background: transparent;
  box-shadow: none;
  position: relative;
  min-height: 80px;
}

.workprogressdivcricle {
  padding: 5px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #02cc52;
  /* background: #02CC52; */
  color: #fff;
  border: none;
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workprogressdivcricletxtp {
  font: 500 20px/25px Roboto;
}

.workprogressdivcricletxtpdes {
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 15px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 55px;
  color: #000;
  white-space: nowrap;
  font: 400 16px/20px Roboto;
}

.selectpalndivnewcontent {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.contatcindofdiinpurtxtpdivmaoibjj {
  width: 100%;
  max-width: 40%;
  min-width: 40%;
}

.dropwdownseletcmewdivfrmbusinee {
  width: 100%;
  max-width: 60%;
  min-width: 60%;
  padding: 18px 22px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  position: relative;
}

.basicjleisttxtp {
  font: 500 20px/22px Roboto;
  color: #000;
}

.Workprogressbarmainb {
  margin-bottom: 10px;
}

.paymentpagesectionmain {
  width: 100%;
  height: auto;
  padding: 85px 0px 0px 0px;
}

.shoppingcontinue {
  width: 100%;
  height: auto;
}

.shoppingcontinuebg {
  width: 100%;
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 10px;
}

.paymentshpinhgcomp {
  width: 100%;
  height: auto;
}

.shpingtxttxpp {
  font: 500 18px/22px Roboto;
  color: #000;
}

.arrowmaindesuv i {
  font-size: 17px;
  color: #000;
}

.arrowmshpingtasuvdivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  border-bottom: 2px solid #ccc;
  padding: 20px 0px;
}

.shoopingcarddivmain {
  width: 100%;
  height: auto;
  padding-top: 40px;
}

.shoopingcarddivflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 65px;
}

.shoopingcarddivleft {
  width: 100%;
  max-width: 55%;
  min-width: 55%;
}

.shoopingcarddivright {
  width: 100%;
  max-width: 37%;
  min-width: 37%;
}

.shpingtxttxppdesctxtp {
  font: 400 14px/16px Roboto;
  color: #000;
}

.allpancarddiovmain {
  width: 100%;
  padding-top: 30px;
}

.allpancarddiovbrdr {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 20px;
  border-radius: 10px;
}

.allpancarddiovbrdrdsplflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.allpancarddiovbrdrmain {
  width: 100%;
  max-width: 5%;
  min-width: 5%;
}

.alllogopayemdtdibmabk {
  width: 100%;
  max-width: 12%;
  min-width: 12%;
}

.alllogopayemdtdvdata {
  width: 100%;
  max-width: 42%;
  min-width: 42%;
}

.alllogopayemdtdvdatanewleft {
  width: 100%;
  max-width: 22%;
  min-width: 22%;
}

.alllogopayemdtdibmabkdiv {
  width: 100%;
  max-width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #5c2881;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alllogopayemdtdibimg {
  width: 100%;
  max-width: 28px;
  min-width: 28px;
  height: 28px;
}

.alllogopayemdtdibimg img {
  width: 100%;
  height: 100%;
}

.allpancarddiovbrdrchckbox {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
}

.allpancarddiovbrdrchckboxown {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
}

.pandivisttstxppp {
  font: 400 18px/20px Roboto;
  color: #000;
}

.pandivisttstxpppdescttxp {
  font: 400 12px/16px Roboto;
  color: #000;
}

.pandivisttstxpppdediv {
  padding-top: 8px;
}

.dataplannewlefttxp {
  font: 400 14px/16px Roboto;
  color: #000;
  text-align: right;
}

.dataplannewlefttxpaladA {
  font: 400 12px / 14px Roboto;
  color: #000;
  text-align: right;
  padding-top: 31px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox */
.custom-checkbox-box {
  width: 22px;
  height: 22px;
  background-color: #ccc;
  border: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* Add custom styling when the checkbox is checked */
.custom-checkbox input[type="checkbox"]:checked + .custom-checkbox-box {
  background-color: #0787fd;
  /* Example color when checked */
  border: none;
  position: relative;
}

/* Optionally, add some checkmark styling */
.custom-checkbox-box:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input[type="checkbox"]:checked + .custom-checkbox-box:after {
  display: block;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.allpancarddiovbrdr {
  margin-bottom: 15px;
}

.perdiscoutctppsmmjtxtpp {
  font: 400 12px / 16px Roboto;
  color: #000;
  padding-top: 8px;
}

.subtotaltxtppp {
  font: 400 18px/22px Roboto;
  color: #000;
}

.subtotaltxtppp span {
  font: 600 18px/20px Roboto;
  color: #000;
}

.subtotaltxtpppdivmain {
  padding-top: 20px;
}

.shoopingcarddivbrdrmain {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
}

.shoopingcarddmaimndivflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #aeaeb2;
  padding-bottom: 10px;
}

.basicplavtxtppppmnatpp {
  font: 600 20px/24px Roboto;
  color: #000;
}

.deleteicondiv i {
  font-size: 20px;
  color: #000;
}

.drpdownyeartptaleddiv {
  padding: 20px 0px;
  border-bottom: 1px solid #aeaeb2;
}

.drpdownyeartptaleddivflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drpdownyeartptaledleft {
  width: 100%;
  max-width: 50%;
  min-width: 50%;
}

.drpdownyeartptaledright {
  width: 100%;
  max-width: 40%;
  min-width: 40%;
}

.drowdowncustdivbrdr {
  width: 100%;
  border: 1px solid #000;
  padding: 12px 15px;
  border-radius: 10px;
}

.drowdowncusttetiixdivdivbrdr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 55px;
}

.drowdownsettxtppss {
  font: 500 16px/18px Roboto;
  color: #000;
}

.drowdownsettxtppssiciih i {
  font-size: 16px;
}

.aesdtxttpppttxpp {
  font: 600 18px/22px Roboto;
  color: #000;
  text-align: right;
}

.Busilistingnesstxtp {
  font: 400 16px/20px Roboto;
  color: #000;
  padding-top: 10px;
}

.divpromiocodesavedivmain {
  padding: 20px 0px;
  border-bottom: 1px solid #aeaeb2;
}

.enterpromocodegxtpp {
  font: 500 15px/18px Roboto;
  color: #000;
}

.prpmocodedivbrdr {
  width: 100%;
  position: relative;
  padding-top: 15px;
}

.prpmocodedivbrdrinput {
  border: 1px solid #cdcdcd;
  background-color: #ebebeb;
  width: 100%;
  color: #000;
  padding: 15px 80px 15px 10px;
  border-radius: 5px;
  font: 400 14px/16px Roboto;
}

.promocodeebterdivbtn {
  background-color: #000;
  color: #fff;
  font: 400 14px / 16px Roboto;
  border: none;
  outline: 0;
  border-radius: 5px;
  padding: 6px 15px;
  position: absolute;
  top: 26px;
  right: 20px;
}

.applyttxpp {
  color: #0483f8;
  font: 400 16px/20px Roboto;
  text-align: center;
  padding-top: 15px;
  cursor: pointer;
}
.alltotalpayemtxtswneoodiv {
  padding: 20px 0px;
  border-bottom: 1px solid #aeaeb2;
}
.alltotalpayemtxtstablemain {
  width: 100%;
}
.alltotalpayemtxtstable {
  width: 100%;
}
.alltabledatatppptxtpp {
  font: 600 14px/18px Roboto;
  color: #000;
}
.alltabledatatrightppptxtpp {
  font: 600 14px/18px Roboto;
  color: #000;
  text-align: right;
}
.alltotalpayemtxtstabletd {
  padding-bottom: 8px;
}
.aedtbtbtxttppp {
  font: 500 14px/16px Roboto;
  color: #fff;
}
.aedtbtbtxttpppchckttp {
  font: 500 14px/16px Roboto;
  color: #fff;
}
.aedtbtbtxttpppdivbytbntbtn {
  background-color: #0b0b0b;
  width: 100%;
  padding: 15px;
  border: none;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-top: 40px;
}
.arrowdibbnammcttap {
  margin-left: 15px;
  color: #fff;
  font: 400 14px 16px Roboto;
}
.trmsacondtiontxtp {
  font: 500 20px/22px Roboto;
  color: #000;
}
.trmsconditiontxtul {
  list-style-type: decimal;
  padding-top: 20px;
}
.trmsconditiontxtli {
  font: 400 14px/18px Roboto;
  color: #000;
  padding-bottom: 5px;
}

.promoModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-height: 80vh;
  overflow-y: auto;
}

.modalContent h3 {
  margin-top: 0;
}

.modalContent ul {
  list-style: none;
  padding: 0;
}

.modalContent li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modalContent button {
  margin-top: 20px;
}
.member_section_new_class{
  display: flex;
  column-gap: 10px;
}
.left_analtics_div_wrapper{
  width: 100%;
  max-width: 27%;
  min-width: 27%;
  height: 100%;
  border-radius: 12px;
}
.left_analtics_container{
  background-color: white;
  border-radius: 12px;
  padding-bottom: 10px;
}
.export_div_wrapper{
  margin-top: 12px;
  background-color: white;
}
.right_analtics_div{
  width: 100%;
  max-width: 72%;
  min-width: 72%;
  height: 100%;
 
}
.right_analtics_div_container{
  background-color: white;
  border-radius: 12px;
}
.left_analtics_logo{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  padding: 20px 25px;
  border-bottom: 1px solid rgba(190, 190, 190, 1);
}
.analytics_img_div{
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
}
.analytics_img_div img{
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
}
.left_analtics_logo p {
  font-family: Roboto;
font-size: 24px;
font-weight: 500;
}
.left_analtics_links_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  padding: 13px 10px 14px 10px;
}
.nav_link_wrapper .active{
background-color: rgb(229, 246, 254);
border-radius: 10px;
}
.nav_link_wrapper .active p{
  color: black;
}
.left_analtics_link_img {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
}
.left_analtics_link_img  img{
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
.left_analtics_links_wrapper p {
font-family: Nunito;
font-size: 18px;
font-weight: 600;
margin-top: 5px;
color: black;

}
.blm_page_dropdown_div{
  padding: 5px 50px 20px 50px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.blm_page_dropdown_div p {
  font-size: 18px;
  font-weight: 500;
  font-family: Nunito;

}
.export_div_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}
.export_div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}
.export_div p{
  font-family: Roboto;
font-size: 16px;
font-weight: 700;
}
.export_img{
  width: 20px;
  margin-top: -5px;
}
.export_img img{

  width: 100%;
  height: 100%;
}
.overview_wrapper{
  border-bottom: 1px solid rgba(190, 190, 190, 1);
  height: 57px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.overview_wrapper p {
font-family: Nunito;
font-size: 16px;
font-weight: 600;
}
.filter_box{
  width:fit-content;
  border: 1px solid rgba(172, 172, 172, 1);
  padding: 7px 7px;
  border-radius: 8px;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap:10px;
}
.filter_box p {
  padding: 3px 6px;
  background-color: rgb(231, 231, 231);
  font-size: 9px;
  border-radius: 7px;
  text-align: center;
}
.filter_box_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}
.filter_box span{
font-family: Inter;
font-size: 13px;
font-weight: 500;
color:  rgba(109, 109, 109, 1);
}
.enquires_wrapper{
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background-color: white;
  border-radius: 12px;
  border: 1px solid rgb(215, 215, 215);
  display: flex;
  padding: 17px 0px;
  margin-top: 10px;
}
.enquire_div_1{
  width: 100%;
  max-width: 25%;
  min-width: 25%;
  border-right: 1px solid rgba(190, 190, 190, 1);
  padding: 10px 20px;
}
.enquire_div_1:nth-child(4){
  border: none !important;
}
.enquire_div_1 p {
font-family: Nunito;
font-size: 12px;
font-weight: 600;
}
.enquire_div_1 h3{
font-family: Nunito;
font-size: 24px;
font-weight: 700;
margin-top: 15px
}
.right_box_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
}
.right_box_div{
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06);
box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1);
background-color: white;
padding: 20px 15px;
width: 100%;
max-width: 30.5%;
min-width: 30.5%;
border-radius: 12px;
}
.right_box_div p{
font-family: Inter;
font-size: 18px;
font-weight: 500;
}
.right_box_div span{
font-family: Inter;
font-size: 28px;
font-weight: 500;
}
.star_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
}
.star_wrapper2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.star_wrapper2 h6{
  padding: 4px 7px;
  border-radius: 5px;
  color: rgb(88, 202, 147);
  background-color: rgb(237, 254, 244);
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 3px;
  border-radius: 10px;
}
.star_wrapper3{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.star_wrapper3 h6{
  padding: 4px 7px;
  border-radius: 5px;
  color: rgb(219, 115, 111);
  background-color: rgb(255, 232, 228);
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 3px;
  border-radius: 10px;
}
.right_analtics_table_wrapper{
  background-color: white;
  height: 500px;
  margin-top: 10px;
  border-radius: 12px;
  padding-right: 10px;
}
.select_boxes{
  width: 100%;
  max-width: 80%;
  min-width: 80%;
  display: flex;
  justify-content: end;
  column-gap: 10px;
}
.select_boxes select{
  width: 100%;
  max-width: 27%;
  min-width: 27%;
  height: 37px;
  border-radius: 8px;
  padding: 0px 5px;
  outline: none;
  border: none;
  background-color: rgb(250, 250, 250);
  border: 1px solid  rgba(229, 230, 234, 1);
font-family: Nunito;
font-size: 14px;
font-weight: 400;

}
.select_boxes_2{
  width: 100%;
  max-width: 70%;
  min-width: 70%;
}
.select_boxes input{
  width: 100%;
  max-width:15%;
  min-width:15%;
  height: 37px;
  border-radius: 8px;
  padding: 0px 5px;
  outline: none;
  border: none;
  background-color: rgb(250, 250, 250);
  border: 1px solid  rgba(229, 230, 234, 1);
font-family: Nunito;
font-size: 14px;
font-weight: 400;

}
.account_wrapper{
  background-color: rgb(255, 255, 255);
  padding: 40px 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
.active_class{
  background: linear-gradient(to top right,rgba(241, 118, 91, 1),rgba(240, 93, 158, 1));
}
.account_1{
  width: 100%;
  /* max-width: 31%;
  min-width: 31%; */
  max-width: 23%;
  min-width: 23%;
  border-radius: 8px;
  border: 1px solid rgba(190, 190, 190, 1);
  padding: 10px 15px;
  color: black !important;
}

.account_logo_div{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: linear-gradient(to top right,rgba(241, 118, 91, 1),rgba(240, 93, 158, 1));
  display: flex;
  justify-content: center;
  align-items: center;
}
.active_class .account_logo_div{
  background-color: rgba(255, 255, 255, 0.272) !important;
}
.account_logo_div img{
  width: 12px;
}
.account_logo_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
}
.active_class p{
  color: white !important;
}
.account_logo_wrapper p{
  color: black;
  font-size: 16px;
  font-weight: 500;
}
.account_reached_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 10px;
}
.account_reached_wrapper h5{
  color: black;
  font-size: 28px;
}
.active_class h5{
  color: white;
}
.active_class h4{
  color: white !important;
}
.active_class h6{
  color: white !important;
}
.account_reached_wrapper h4{
  color: black;
  font-size: 12px;
  font-weight: 500;
}
.account_reached_wrapper h6{
  color: black;
  font-size: 13px;
  font-weight: 400;
 background: rgba(255, 255, 255, 0.15);
 border-radius: 10px;
 padding: 2px 15px;
  ;
}
.all_actives_wrapper{
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(195, 195, 195, 1)
}
.all_actives_wrapper h2{
font-family: Nunito;
font-size: 20px;
font-weight: 700;
}
.right_table_content_wrapper::-webkit-scrollbar{
  width: 5px;
}
.right_table_content_wrapper::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 5px;
}
.right_table_content_wrapper{
  height: 420px;
  overflow-y: scroll;
}
.company_logo_details_Wrappper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  padding: 20px 12px;

}
.table_data_1{
  width:310px;
  min-width:310px;
}
.table_data_2{
  width:200px;
  min-width:200px;
 color:  rgba(104, 109, 125, 1);
font-family: Nunito;
font-size: 14px;
font-weight: 400;
}
.table_data_3{
  width:150px;
  min-width:150px;
 color:  rgba(104, 109, 125, 1);
font-family: Nunito;
font-size: 14px;
font-weight: 400;
}
.table_data_4{
  width: 115px;
  min-width: 115px;
}
.logo_img656{
  width: 47px;
  height: 47px;
  border-radius: 50%;
}
.logo_img656 img{
  width: 100%;
  height: 100%;
}
.logo_details656 p{
  font-family: Inter;
font-size: 15px;
font-weight: 400;
}
.logo_details656 span{
  font-family: Inter;
font-size: 13px;
font-weight: 400;
margin-top: -10px;
}
.custom_modal_wrap{
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100vh;
  background-color: rgba(235, 235, 235, 0.487);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}
.custom_modal_container{
  width: 100%;
  max-width: 40%;
  min-width: 40%;
  padding: 30px 0px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  z-index: 99999999;
  border: 1px solid rgb(164, 164, 164);
  border-radius: 8px;
  box-shadow: 0px 0px 5px #ccc;
}
.custom_green{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #009e60;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto 0 auto;
}
.custom_green i{
  color: #fff;
  font-size: 40px;
}
.custom_modal_container h3{
  font-family: Roboto;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
}
.custom_modal_container h5{
  font-family: Roboto;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
}
.custom_modal_container h6{
  font-family: Roboto;
  text-align: center;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 400;
}
.custom_modal_container p{
  font-family: Roboto;
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
}
.done_btn_button{
  width: fit-content;
  padding: 8px 40px;
  background-color: rgb(170, 169, 169);
  border-radius: 10px;
  border: none;
  font-family: Roboto;
  font-weight: 600;
  color: #fff;
}
.rating_button{
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid rgb(103, 48, 142);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  font-size: 13px;
  font-weight: 500;
  margin: -12px auto 0 auto;
}
.modal_wrapper_main8789{
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100vh;
  background-color: rgba(22, 22, 22, 0.487);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}
.rating_modal_div{
  width: 100%;
  max-width: 40%;
  min-width: 40%;
  border-radius: 8px;
  background-color: white;
  z-index: 999999999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  padding: 35px 25px;
  
}
.cross_btn89{
  position: absolute;
  top: 10px;
  right:20px ;
  font-size: 25px;
  cursor: pointer;
}
@media (max-width: 1499px) {
  .searchhomemain {
    width: 100%;
    max-width: 100%;
    height: 625px;
  }
  .table_data_4 {
    width: 100px;
    min-width: 100px;
}
  .starratingbratdivflxsgivebtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 29px;
    padding: 0px 0px 0px 10px;
  }

  .ratingffsgivebtn {
    border: 1px solid #ab4ad9;
    padding: 8px 6px;
    color: #000;
    font-size: 13px;
    line-height: 18px;
    max-width: fit-content;
    min-width: fit-content;
    column-gap: 5px;
  }

  .starratingothedifiub {
    padding: 15px 0px 30px 4px;
  }

  .ratingffsgivebtnimgnain {
    width: 100%;
    max-width: 18px;
    min-width: 18px;
    height: 18px;
  }

  .starratingothedifiub .star-svg {
    margin-left: 3px;
  }

  .ipyysffraedtn {
    background-color: #089f29;
    color: #fff;
    font-size: 14px;
    padding: 6px 18px;
  }

  .ingfddtertrrxrpp {
    font: 400 13px / 17px Roboto;
    width: 100%;
    max-width: fit-content;
    min-width: fit-content;
  }

  .indfodetatildiv {
    width: 100%;
    /* max-width: 17px; */
    min-width: 17px;
    height: 22px;
  }

  .abttsrtxtdivvnappadin {
    padding: 28px 0px 15px 0px;
  }

  .adversytfservicexedib {
    padding: 20px 0px 28px 0px;
  }

  .uploadimagedivflx {
    margin: 38px 0px 16px 0px;
  }

  .bmlmmaposttaddddibdivmain {
    padding: 15px;
  }

  .fallsebblldivmaindivflxl {
    padding: 30px 0px 10px 0px;
  }

  .tavbedebieisdtldivtrd {
    color: #000;
    font-size: 14px;
  }

  .vuisrrcannsiittxtp {
    font-size: 15px;
  }

  .likesfolwoerdivfxls {
    padding: 12px 45px;
  }

  .alkantsrepposttxtppqueiytettcpp {
    font: 400 18px / 22px Roboto;
  }

  .likedfollowdivflx {
    padding: 5px 20px 10px 20px;
  }

  .servicdesdetatcxttxpp {
    font: 400 17px / 20px Roboto;
  }

  .foolowndnbyhbnbtn {
    width: 100%;
    max-width: 105px;
    min-width: 105px;
    font-size: 16px;
  }

  .abttsbttetxtttpp {
    max-width: 550px;
    min-width: 550px;
  }

  .viewsandebgagemntdivmain {
    padding: 15px;
  }

  .bannerloginbgcontent {
    left: 27%;
    transform: translate(-30%, -50%);
  }

  .busineeheadpara {
    width: 100%;
    max-width: 603px;
    font-size: 50px;
  }

  .Searchloginformmainleft {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
  }

  .alkantsrepposttxtpp {
    font: 400 18px / 22px Roboto;
    color: #000;
  }

  .embermastersectionbgbobgmainnbg {
    width: 100%;
    padding: 40px 55px;
    column-gap: 20px;
  }

  .Searchloginformmainright {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
  }

  .Searchloginformmainrightbg {
    padding: 50px 50px;
    width: 100%;
    max-width: 100%;
  }

  .agreerxtppptt {
    font-size: 14px;
  }

  .Searchloginformmainleftimg {
    width: 100%;
    max-width: 100%;
    height: 630px;
  }

  .likedfollowlike {
    width: 100%;
    /* max-width: 80px; */
    min-width: 80px;
    height: 40px;
    font-size: 16px;
  }

  .poisyybtnmain {
    font-size: 18px;
    padding: 9px 26px;
    border-radius: 17px;
  }

  .businesssubparapara {
    color: #fff;
    font-size: 33px;
    font-weight: 300;
  }

  .auuhshtxttpp {
    font: 500 16px / 22px Roboto;
  }

  .chooseplandivmaondivbrdrmain {
    padding: 25px 0px;
  }

  .baysyysttxtppp {
    font: 500 18px / 22px Roboto;
  }

  .choosttappanbtn {
    width: 100%;
    max-width: 190px;
    min-width: 190px;
    padding: 6px 16px;
  }

  .poisyybtnmain {
    font-size: 14px;
    padding: 9px 23px;
    border-radius: 17px;
  }

  .infodetatddivfllsxdlx {
    column-gap: 11px;
  }

  .uploadbannerdivbg {
    height: 246px;
  }

  .uploadimagedivflx {
    column-gap: 16px;
  }

  .businedirreidovmainnedpoingdiright {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
    padding-top: 20px;
  }

  .businedirreidovmainnedpoingdimain {
    padding-bottom: 25px;
  }

  .businesssubparaparasecrtstt {
    color: #fff;
    font-size: 28px;
    font-weight: 300;
    width: 100%;
    max-width: 757px;
    padding-top: 15px;
  }

  .businedirreidovmainttxpp {
    font: 300 24px / 27px Roboto;
    color: #000;
    /* width: 100%; */
  }
}

@media (max-width: 1399px) {
  .account_1{
    width: 100%;
    max-width: 45%;
    min-width: 45%;
  }
  .starratingbratdivflxsgivebtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 18px;
    padding: 0px 0px 0px 8px;
  }

  .businedirreidovmainttxpp {
    font: 300 23px / 26px Roboto;
    color: #000;
    /* width: 100%; */
  }

  .bg-whtdivnmanbdivmaimdivfexrt {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
    padding-bottom: 25px;
  }

  .ratingffsgivebtn {
    padding: 8px 5px;
    color: #000;
    font-size: 11px;
    line-height: 18px;
    column-gap: 5px;
  }

  .alkantsrepposttxtpp {
    font: 400 17px / 21px Roboto;
    color: #000;
  }

  .membermastersectionbgbottomdivflx {
    column-gap: 25px;
  }

  .embermastersectionbgbobgmainnbg {
    width: 100%;
    padding: 40px 45px;
    column-gap: 20px;
  }

  .busineeheadparadirecrpara {
    color: #fff;
    font-size: 55px;
    line-height: 58px;
    font-family: Roboto;
    width: 100%;
    max-width: 773px;
    font-weight: 600;
  }

  .businedirreidovmain {
    padding: 40px 166px 40px 100px;
  }
}

@media (max-width: 1270px) {
  .Searchloginformmainrightbg {
    padding: 50px 38px;
    width: 100%;
    max-width: 100%;
  }

  .uploadimagebgmain {
    height: 130px;
  }

  .businedirreidovmainttxpp {
    font: 300 20px / 24px Roboto;
    color: #000;
    /* width: 100%; */
  }

  .editprofile {
    width: 100%;
    max-width: 130px;
    min-width: 130px;
    height: 130px;
  }

  .busineeheadparadirecrpara {
    color: #fff;
    font-size: 53px;
    line-height: 57px;
    font-family: Roboto;
    width: 100%;
    max-width: 790px;
    font-weight: 600;
  }

  /* .businedirreidovmain {
        padding: 40px 80px;
    }     */

  .serctedheadrparatxt {
    font: 400 19px / 24px Roboto;
    color: #072eba;
  }

  .uploadbannerdivbg {
    height: 231px;
  }

  .ingfddtertrrxrpp {
    font: 400 12px / 15px Roboto;
    width: 100%;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

  .allttehdiioskjetyapasedoib {
    width: 100%;
    max-width: 90%;
    min-width: 90%;
    padding-top: 5px;
  }

  .logostrdetyskksldovkkdpo {
    /* padding: 20px; */
    padding: 0px 12px;
  }

  .statrrdibvggshhghuju i {
    color: #fbb03b;
    margin-left: 6px;
  }

  .dofleoodiistartdivflx {
    display: flex;
    justify-content: flex-start;
    column-gap: 46px;
  }

  .ownseacrgresultpagerightbrdrtopbottom {
    width: 100%;
    padding: 15px 20px 15px 72px;
  }

  .diisjadfebnclkmdgragjksopjkl {
    font: 400 12px / 17px Roboto;
    color: #000;
  }

  .cinnattddttdtsttspo {
    font: 500 16px / 20px Roboto;
    color: #000;
  }

  .freesttxppp {
    font: 500 24px / 32px Roboto;
  }

  .starratingothedifiub {
    padding: 15px 0px 30px 0px;
  }

  .choosttappanbtn {
    width: 100%;
    max-width: 170px;
    min-width: 170px;
    padding: 6px 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    height: 40px;
  }

  .auuhshtxttpp {
    font: 500 14px / 20px Roboto;
  }

  .foolowndnbyhbnbtn {
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    color: #fff;
    font-size: 16px;
  }

  .poisyybtnmain {
    font-size: 14px;
    padding: 7px 16px;
  }

  .poisyybtnmain i {
    margin-left: 10px;
    font-size: 14px;
  }

  .viewallpostlnk {
    font: 400 14px / 19px Roboto;
  }

  .likesfolwoerdivfxls {
    padding: 12px 25px;
  }

  .poiosyyattatxtpp {
    font-size: 13px;
    color: #000;
    line-height: 20px;
    width: 100%;
    max-width: 200px;
    min-width: 200px;
  }

  .ownseacrgresultpagerreslppimg {
    width: 100%;
    height: 148px;
  }

  .businesssubparaparasecrtstt {
    color: #fff;
    font-size: 25px;
    font-weight: 300;
    width: 100%;
    max-width: 757px;
    padding-top: 15px;
  }
}

@media (max-width: 1199px) {
  .Searchloginformmainleft {
    width: 100%;
    max-width: 48%;
    min-width: 48%;
  }
.right_box_div{
  width: 100%;
max-width: 46.5%;
min-width: 46.5%;
}
  .businedirreidovmainttxpp {
    font: 300 18px / 22px Roboto;
    color: #000;
    /* width: 100%; */
  }

  .businedirreidovmain {
    padding: 40px 113px 40px 100px;
  }

  .makednekleosearchtxtpp {
    font: 500 30px / 38px Roboto;
    color: #000;
    width: 100%;
    max-width: 480px;
    margin: auto;
    text-align: center;
  }

  .WhatmakesNeoosearchtxtp {
    font: 400 20px / 25px Roboto;
    color: #000;
    width: 100%;
    max-width: 860px;
    margin: auto;
    text-align: center;
    padding: 20px 0px;
  }

  .busineeheadparadirecrpara {
    color: #fff;
    font-size: 47px;
    line-height: 57px;
    font-family: Roboto;
    width: 100%;
    max-width: 790px;
    font-weight: 600;
  }

  .bg-whtdivnmanbdivmaimdivfexrt {
    width: 100%;
    max-width: 38%;
    min-width: 38%;
    padding-bottom: 25px;
  }

  .Promotetxtpp {
    font: 500 21px / 27px Roboto;
  }

  .freetetxtppp {
    font: 400 14px / 18px Roboto;
    color: #000;
    text-align: center;
  }

  .bg-whtdivnmanbdivmaimaindiv {
    padding: 20px 35px;
  }

  .businedirreidovmainnedpoingdiright {
    width: 100%;
    max-width: 48%;
    min-width: 48%;
    padding-top: 0px;
  }

  .businedirreidovmainnedpoingdimain {
    padding-bottom: 20px;
  }

  .postimagedesrcptoioponttxp {
    font: 400 12px / 15px Roboto;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 4; */
    margin-bottom: 5px;
  }

  .embermastersectionbgbobgmainnbg {
    width: 100%;
    padding: 40px 28px;
    column-gap: 20px;
  }

  .embermastersectionbgbobgmai {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
  }

  .alkantsrepposttxtpp {
    font: 400 16px / 21px Roboto;
    color: #000;
  }

  .postbanneimmhgdivjakhjimh {
    width: 100%;
    max-width: 150px;
    min-width: 150px;
    height: 100px;
  }

  .neeporoalposttsheadr {
    font: 500 15px / 20px Roboto;
    color: #1d1d1d;
    margin-bottom: 5px;
  }

  .starratingbratdivflxsgivebtn {
    column-gap: 10px;
    padding: 0px 0px 0px 0px;
  }

  .crosasddoproidikdsjbhidivflx {
    column-gap: 15px;
  }

  .starratingbratdivflxsgivebtn {
    flex-wrap: wrap;
    justify-content: center;
  }

  .starratingothedifiub {
    padding: 0px 0px 8px 0px;
  }

  .enquersrtsimgagmmubnnmgbtn {
    column-gap: 15px;
    padding: 11px 17px;
    font: 400 16px / 21px Roboto;
    border-radius: 10px;
  }

  .sendwieidjnndnmainbbth {
    padding: 15px 19px;
  }

  .tavbedebieisdtldiv {
    width: 100%;
    max-width: 95%;
    min-width: 95%;
  }

  .tavbedebieisdtldivtrd {
    color: #000;
    font-size: 13px;
  }

  .vuisrrcannsiittxtp {
    font-size: 13px;
  }

  .wehelpsppeottxpp {
    font: 400 9px / 14px Roboto;
    color: #000;
    margin-bottom: 17px;
  }

  .abttsbttetxtttpp {
    max-width: 450px;
    min-width: 450px;
  }

  .infodetatddivfllsxdlx {
    column-gap: 6px;
  }

  .indfodetatildiv {
    width: 100%;
    /* max-width: 13px; */
    min-width: 12px;
    height: 16px;
  }

  .imagetitrtlstrtrxpp {
    font: 400 14px / 17px Roboto;
  }

  .uploadimagebgmain {
    height: 120px;
  }

  .direxrrcttstbtn {
    font-size: 12px;
    padding: 7px 20px;
  }

  .uploadbannerdivbg {
    height: 230px;
  }

  .servicdesdetatcxttxpp {
    font: 400 15px / 18px Roboto;
  }

  .direxrrcttstbtn img {
    width: 100%;
    max-width: 14px;
    min-width: 14px;
    height: 13px;
    margin-left: 9px;
  }

  .ipyysffraedtn {
    background-color: #089f29;
    color: #fff;
    font-size: 12px;
    padding: 6px 12px;
  }

  .editprofile {
    width: 100%;
    max-width: 120px;
    min-width: 120px;
    height: 120px;
  }

  .poiosyyattatxtpp {
    font-size: 12px;
    color: #000;
    line-height: 17px;
    width: 100%;
    max-width: 164px;
    min-width: 165px;
  }

  .poisyybtnmain {
    font-size: 13px;
    padding: 6px 10px;
  }

  .viewallpostlnk {
    font: 400 12px / 16px Roboto;
  }

  .choosttappanbtn {
    width: 100%;
    max-width: 125px;
    min-width: 125px;
    font-size: 16px;
  }

  .chooseplandivmaondivbrdrmain {
    padding: 25px 0px;
  }

  .cameralogodivimg {
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 19px;
    margin: auto;
    margin-bottom: 10px;
  }

  .uplsdttxttppshji {
    font-size: 12px;
  }

  .pixelsttxttppio {
    font-size: 10px;
  }

  .vieiimitrrebtn {
    color: #656565;
    font: 400 18px / 22px Roboto;
  }

  .Viewbtnmain {
    padding: 18px 0px 0px 0px;
  }

  .ocensttxttpppp {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    line-height: 23px;
  }

  .likedfollowlike img {
    width: 100%;
    height: 100%;
    max-width: 16px;
    min-width: 14px;
    height: 20px;
  }

  .foolowndnbyhbnbtn {
    width: 100%;
    max-width: 70px;
    min-width: 70px;
    color: #fff;
    font-size: 12px;
    padding: 4px 15px;
  }

  .starrating {
    padding: 15px 7px 30px 8px;
  }

  .star-svg {
    margin-left: 5px;
  }

  .likedfollowlike {
    width: 100%;
    /* max-width: 62px; */
    min-width: 62px;
    height: 29px;
    font-size: 14px;
    padding: 6px;
  }

  .likesfolwoerdivfxls {
    padding: 12px 22px;
  }

  .contatcindofdiinpur {
    width: 100%;
    max-width: 47%;
    min-width: 47%;
    padding-bottom: 20px;
  }

  .icopndibimahhmainimjhdibmai {
    width: 100%;
    max-width: 100%;
    padding: 15px 16px;
  }

  .busineeheadpara {
    font-size: 46px;
    line-height: 61px;
  }

  .businesssubparapara {
    color: #fff;
    font-size: 30px;
    font-weight: 300;
  }

  .listbusinesstxtpp {
    color: #000;
    font-size: 26px;
    line-height: 31px;
  }

  .vectoraarowdivomgulmai {
    width: 100%;
    max-width: 77%;
    min-width: max-content;
    padding: 10px;
    top: 21px;
    left: 9px;
  }

  .serachresultlogoidiv {
    width: 100%;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .serctedheadrparatxt {
    font: 400 16px / 23px Roboto;
    color: #072eba;
  }

  .ownseacrgresultpageleft {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
  }

  .ownseacrgresultpageright {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
  }

  .Searchloginformmainright {
    width: 100%;
    max-width: 52%;
    min-width: 52%;
  }

  .agreerxtppptt {
    font-size: 12px;
  }

  .vectoraarowdivomgulmaili {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 9px;
    cursor: pointer;
  }

  .inpustxocntbusuitxtpp {
    width: 100%;
    max-width: 100%;
  }

  .dropwdowndivfrmbusinee {
    width: 100%;
    max-width: 100%;

    border: 1px solid #b4b4b4;
    border-radius: 5px;
  }

  .vectoraarowdivomg {
    position: absolute;
    top: 17px;
    right: 23px;
    width: 100%;
    max-width: 18px;
    min-width: 18px;
    height: 21px;
  }

  .alkantsrepposttxtppqueiytettcpp {
    font: 400 16px / 21px Roboto;
  }

  .businesssubparaparasecrtstt {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    width: 100%;
    max-width: 590px;
    padding-top: 15px;
  }

  .listbusinessdivleftbg {
    padding: 30px 20px;
  }
}
 @media (max-width:1099px){
  .rating_modal_div{
    width: 100%;
    max-width: 50%;
    min-width: 50%;
  }
  .member_section_new_class{
    display: block;
  }
  .left_analtics_div_wrapper{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
  }
  .right_analtics_div{

    width: 100%;
    max-width: 100%;
    min-width: 100%;

  }
 }
@media (max-width: 991px) {
  .rating_modal_div{
    width: 100%;
    max-width: 55%;
    min-width: 55%;
  }
  .searchhomemain {
    width: 100%;
    max-width: 100%;
    height: 619px;
  }

  .embermastersectionbgbobgmainnbg {
    width: 100%;
    padding: 40px 20px;
    column-gap: 10px;
  }

  .busineeheadparadirecrpara {
    color: #fff;
    font-size: 41px;
    line-height: 48px;
    font-family: Roboto;
    width: 100%;
    max-width: 790px;
    font-weight: 600;
  }

  .businesssubparaparasecrtstt {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    width: 100%;
    max-width: 590px;
    padding-top: 15px;
  }

  .busineeheadpara {
    width: 100%;
    max-width: 603px;
    font-size: 41px;
    line-height: 56px;
  }

  .businedirreidovmainttxpp {
    font: 300 20px / 25px Roboto;
    color: #000;
  }

  .businedirreidovmain {
    padding: 40px 48px;
  }

  .businesssubparapara {
    color: #fff;
    font-size: 27px;
    line-height: 36px;
  }

  .basiuicvaytbtu {
    width: 100%;
    max-width: 110px;
    min-width: 110px;
    padding: 6px 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .freesttxppp {
    font: 500 20px / 28px Roboto;
  }

  .Searchloginformmainleft {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
  }

  .repsonsivedivcls {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
  }

  .oenblmsnfidivmainleft {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
  }

  .oenblmsnfidivmainright {
    width: 100%;
    max-width: 60%;
    min-width: 60%;
  }

  .deslrtopwdijjsmain {
    display: none;
  }

  .choosplantxttpp {
    font-size: 30px;
    line-height: 37px;
  }

  .passowrdrestritiondivtop {
    width: 100%;
    background-color: #07ac21;
    position: relative;
    padding: 12px 12px;
  }

  .passowrdrestritiondivbottom {
    padding: 12px 12px;
  }

  .minimumpasswodttrxpp {
    font: 400 12px/16px Roboto;
    color: #fff;
  }

  .fromstartuptxttp {
    font-size: 20px;
    line-height: 26px;
  }

  .uploadbannerdivbg {
    height: 280px;
  }

  .pisckktxtpp {
    font-size: 13.5px;
    line-height: 16px;
  }

  .Searchloginformmainright {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
  }

  .Searchloginformmainleftimg {
    width: 100%;
    max-width: 100%;
    height: 515px;
  }

  .midownseacrhmaindivnew {
    width: 100%;
    max-width: 70%;
    min-width: 70%;
  }

  .contatcindofdiinpur {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-bottom: 20px;
  }

  .listbusinessdivflx {
    flex-wrap: wrap;
  }

  .listbusinessdivleft {
    width: 100%;
    max-width: 100%;
  }

  .dofleoodiistartdivflx {
    display: flex;
    justify-content: flex-start;
    column-gap: 36px;
  }

  .logostrdetyskksldovkkdpo {
    column-gap: 7px;
    /* padding: 20px; */
    padding: 0px 10px;
  }

  .listbusinesstxtpp {
    color: #000;
    font-size: 24px;
    line-height: 30px;
  }

  .contatcindofdiinpurnewalasda {
    width: 100%;
    max-width: 100%;
  }

  .ownblmprofiledivflx {
    flex-wrap: wrap;
  }

  .reposivecideforspecial {
    flex-wrap: wrap;
  }

  .ownblmprofiledivleft {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .ownblmprofiledivright {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .responsivdedivmain {
    display: block;
  }

  .abttsbttetxtttpp {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .uploadbannerdivbg {
    height: 327px;
  }

  .starratingbratdivflxsgivebtn {
    column-gap: 10px;
    padding: 10px 0px 10px 0px;
  }

  .bannerloginbg {
    width: 100%;
    height: 320px;
    position: relative;
  }

  .uploadbannerdivbg {
    height: 274px;
  }
}

@media (max-width: 767px) {
  
.rating_modal_div{
  width: 100%;
  max-width: 70%;
  min-width: 70%;
}
  .member_section_new_class{
    display: block;
  }
  .left_analtics_div_wrapper{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
  }
  .right_analtics_div{

    width: 100%;
    max-width: 100%;
    min-width: 100%;

  }
  .midownseacrhmaindivnew {
    width: 100%;
    max-width: 80%;
    min-width: 80%;
    top: 45%;
  }

  .Searchloginformmainrightbg {
    padding: 50px 38px 125px 38px;
    width: 100%;
    max-width: 100%;
  }

  .businedirreidovmainttxpp {
    font: 300 19px / 23px Roboto;
    color: #000;
  }

  .logostrdetyskksldovkkdpo {
    align-items: baseline;
  }

  .searchhomemain {
    width: 100%;
    max-width: 100%;
    height: 546px;
  }

  .Searchloginformmainbg {
    flex-wrap: wrap;
  }

  .chooseplandivmaondivbrdrmain {
    padding: 25px 0px;
  }

  .embermastersectionbgbobgmai {
    width: 100%;
    max-width: 48%;
    min-width: 48%;
  }

  .embermastersectionbgbobgmainnbg {
    width: 100%;
    padding: 40px 20px;
    column-gap: 10px;
    justify-content: center;
  }

  .alkantsrepposttxtppqueiytettcpp {
    font: 400 21px / 24px Roboto;
    color: #000;
  }

  .membermastersectionbgbottomdivflx {
    column-gap: 20px;
  }

  .alkantsrepposttxtpp {
    font: 400 21px / 24px Roboto;
    color: #000;
  }

  .auuhshtxttpp {
    font: 500 13px / 16px Roboto;
  }

  .ownseacrgresultpagerreslppimg {
    width: 100%;
    height: 130px;
  }

  .logostretdijjkkkljujuo {
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    height: 30px;
    margin-top: 0px;
  }

  .logostrdetyskksldovkkdpo {
    /* padding: 20px; */
    padding: 7px 7px;
  }

  .dofleoodiistartdivflx {
    flex-wrap: wrap;
  }

  .ownseacrgresultpagerightbrdrtopbottom {
    width: 100%;
    padding: 15px 20px 15px 18px;
  }

  .businedirreidovmainnedpoing {
    border-top: 1px solid #cccccc;
    padding: 30px 30px;
  }

  .neosertsttfzrrttyadfdfff {
    width: 100%;
    max-width: 100%;
  }

  .businedirreidovmainnedpoingdibvlcx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 42px;
  }

  .mobilecopydivimage {
    width: 100%;
    height: 390px;
    max-width: 195px;
    min-width: 195px;
  }

  .businedirreidovmainnedpoingdiright {
    width: 100%;
    max-width: 51%;
    min-width: 51%;
    padding-top: 0px;
  }

  .statrrdibvggshhghuju i {
    color: #fbb03b;
    margin-right: 8px;
    margin-left: 0px;
  }

  .baysyysttxtppp {
    font: 500 15px / 20px Roboto;
  }

  .Searchloginformmainleft {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .basiuicvaytbtu {
    width: 100%;
    max-width: 105px;
    min-width: 105px;
    padding: 6px 16px;
    font-size: 16px;
    line-height: 24px;
    height: 37px;
  }

  .chooseplandivmaon {
    padding: 30px 30px;
  }

  .chooseplandivmaondivflx {
    column-gap: 9px;
  }

  .busuyyasbasytxtxtppli {
    font: 400 12px / 18px Roboto;
  }

  .vieiimitrrebtn {
    color: #656565;
    font: 400 14px / 20px Roboto;
  }

  /* .bannerloginbg {
        width: 100%;
        height: 390px;
        position: relative;
    } */

  .busineeheadpara {
    width: 100%;
    max-width: 492px;
    min-width: 492px;
    font-size: 41px;
    line-height: 53px;
  }

  .busineeheadparadirecrpara {
    color: #fff;
    font-size: 32px;
    line-height: 43px;
    font-family: Roboto;
    width: 100%;
    max-width: 633px;
    font-weight: 600;
  }

  .businesssubparapara {
    color: #fff;
    font-size: 24px;
    line-height: 36px;
  }

  .neosertsttfzrrtty {
    font: 500 16px / 18px Roboto;
    color: #000;
  }

  .phokennsjjsnyunnrbtk {
    font: 400 15px / 16px Roboto;
    color: #000;
    margin: 20px 0px 10px 0px;
  }

  .bannerloginbgcontent {
    left: 32%;
    transform: translate(-30%, -50%);
  }

  .Searchloginformmainright {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .businesssubparaparasecrtstt {
    color: #fff;
    font-size: 17px;
    font-weight: 300;
    width: 100%;
    max-width: 915px;
    padding-top: 10px;
  }

  .Searchloginformmainleftimg {
    width: 100%;
    max-width: 100%;
    height: 375px;
  }

  .Searchloginformmainleftimg img {
    object-fit: cover;
  }

  .listbusinesstxtpp {
    color: #000;
    font-size: 20px;
    line-height: 27px;
  }

  .WhatmakesNeoosearchtxtp {
    font: 400 18px / 24px Roboto;
  }

  .makednekleosearchtxtpp {
    font: 500 27px / 32px Roboto;
    color: #000;
    width: 100%;
    max-width: 515px;
    margin: auto;
    text-align: center;
  }

  .bg-whtdivnmanbdivmaimaindiv {
    padding: 20px 10px;
  }

  /* .addedasldibv {
    margin-right: 125px;
}
.btnnexttdivbtnmain {
    margin-right: 125px;
} */
}

@media (max-width: 576px) {
  .searchhomemain {
    width: 100%;
    max-width: 100%;
    height: 547px;
  }

  .bg-whtdivnmanbdivmaimdivfexrt {
    width: 100%;
    max-width: 35%;
    min-width: 35%;
    padding-bottom: 25px;
  }

  .Promotetxtpp {
    font: 500 19px / 27px Roboto;
    height: 52px;
  }

  .busineeheadparadirecrpara {
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    font-family: Roboto;
    width: 100%;
    max-width: 633px;
    font-weight: 600;
  }

  .chooseplandivmaondivflx {
    flex-wrap: wrap;
    row-gap: 30px;
  }

  .embermastersectionbgbobgmai {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
  }

  .businedirreidovmain {
    padding: 30px 25px;
  }

  .businedirreidovmainttxpp {
    font: 300 15px/20px Roboto;
    color: #000;
  }

  .businedirreidovmainnedpoingdibvlcx {
    flex-wrap: wrap;
  }

  .mobilecopydivimage {
    width: 100%;
    height: 410px;
    max-width: 240px;
    min-width: 240px;
    margin: auto;
  }
  .account_1{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .businedirreidovmainnedpoingdiright {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-top: 30px;
  }

  .searfchresultleftdiv {
    width: 100%;
    max-width: 80%;
    min-width: 80%;
    position: relative;
  }

  .alkantsrepposttxtpp {
    font: 400 15px / 19px Roboto;
    color: #000;
  }

  .alkantsrepposttxtppqueiytettcpp {
    font: 400 15px / 19px Roboto;
    color: #000;
  }

  .searchfilterdiv {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
  }

  .phokennsjjsnyunnrbtk {
    font: 400 13px / 20px Roboto;
    color: #000;
    margin: 10px 0px 3px 0px;
  }

  .opperpoodshheyxgbpppn {
    font: 300 12px / 15px Roboto;
    color: #000;
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .bvhhjjapsidhbfbbrkjk {
    background-color: #009e60;
    color: #fff;
    font: 400 12px / 16px Roboto;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
  }

  .socijjjsjkjijnndnk {
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    height: 30px;
    display: block;
  }

  .chooseplandivmaondivbrdr {
    width: 100%;
    max-width: 48%;
    min-width: 48%;
  }

  .neosertsttfzrrtty {
    font: 500 16px / 20px Roboto;
    color: #000;
  }

  .serctedheadrparatxt {
    font: 400 14px / 20px Roboto;
    color: #072eba;
  }

  .oenblmsnfidivmainleft {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .oenblmsnfidivmainright {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .repsonsivedivcls {
    flex-wrap: wrap;
  }

  .busineeheadpara {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    font-size: 33px;
    line-height: 42px;
  }

  .businesssubparaparasecrtstt {
    color: #fff;
    font-size: 13px;
    font-weight: 300;
    width: 100%;
    max-width: 351px;
    padding-top: 6px;
  }

  .uploadbannerdivbg {
    height: 220px;
  }

  .businesssubparapara {
    color: #fff;
    font-size: 20px;
    line-height: 33px;
  }

  .bannerloginbg {
    width: 100%;
    height: 236px;
    position: relative;
  }

  .choosplantxttpp {
    font-size: 25px;
    line-height: 32px;
  }

  .fromstartuptxttp {
    font-size: 17px;
    line-height: 22px;
  }

  .pisckktxtpp {
    font-size: 12px;
    line-height: 16px;
  }

  .listbusinessdivleftbg {
    padding: 20px 20px;
  }

  .choosplantxdivmain {
    padding-top: 15px;
  }

  .logedserchfrminptinptnew {
    width: 100%;
    padding: 9px 40px;
  }

  .usenametxttppmian {
    width: 100%;
    margin-bottom: 10px;
    max-width: 100%;
  }

  .searchiconimgnew {
    position: absolute;
    top: 38px;
  }

  .creattusettxpp {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .Searchloginformmainrightbg {
    padding: 30px 30px;
    width: 100%;
    max-width: 100%;
  }

  .Searchloginformmainleftimg {
    width: 100%;
    max-width: 100%;
    height: 240px;
  }

  .usenametxttppinput {
    width: 100%;
    max-width: 100%;
    border: 1px solid #b4b4b4;
    padding: 6px 15px;
  }

  .showiconnimg {
    top: 37px;
  }

  .conattaindptxtpp {
    color: #000;
    font-size: 17px;
    line-height: 23px;

    margin-bottom: 12px;
  }

  .contatcindofdiinpurtxtp {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 12px;
  }

  .inpustxocntbusuitxtpp {
    width: 100%;
    max-width: 100%;
    padding: 12px 22px;
  }

  .dropwdowndivfrmbusinee {
    height: 50px;
  }

  .vectoraarowdivomg {
    position: absolute;
    top: 12px;
  }

  .allpostowndmaimndiv {
    width: 100%;
    padding: 20px 20px;
    border-bottom: 1px solid #959292;
  }

  .uploadimagedivflx {
    margin: 15px 0px 15px 0px;
    flex-wrap: wrap;
  }

  .uploadimagebg {
    width: 100%;
    max-width: 47%;
  }

  .uploadimagebgmain {
    height: 140px;
  }

  .fallsebblldivmaindivflxl {
    column-gap: 8px;
  }

  .neeporoalposttsheadr {
    font: 500 14px / 18px Roboto;
    color: #1d1d1d;
    margin-bottom: 5px;
  }

  .cmskshhstdiifgysyttxtpop span {
    font: 400 13px / 20px Roboto;
    color: #1d1d1d;
    margin-bottom: 5px;
  }

  .cmskshhstdiifgysyttxtpop {
    font: 400 15px / 20px Roboto;
    color: #1d1d1d;
    margin-bottom: 5px;
  }

  .postbannettimhdivflxmakflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    margin: 20px 0px 5px 0px;
  }

  .allposttslodjjimg {
    width: 100%;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .postbanneimmhgdivjakhjimh {
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    height: 70px;
  }

  .mobilereposiveprofile {
    width: 100%;
    max-width: 100%;
    display: block;
  }

  .mobilereposvispsicaldivflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
    margin: 10px 0px;
  }

  .eidteprofileblmreposnive {
    width: 100%;
    max-width: 48%;
  }

  .eidteprofileblmreposnive2 {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .bannerprofileblmreposnive {
    width: 100%;
    max-width: 100%;
  }

  .editprofile {
    width: 100%;
    max-width: 90px;
    min-width: 90px;
    height: 90px;
    margin-top: 6px;
  }

  .cameralogodivimgabslt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 4px;
    /* z-index: 6; */
  }

  .uplsdttxttppshji {
    font-size: 9px;
  }

  .pixelsttxttppio {
    font-size: 8px;
  }

  .ocensttxttpppp {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    line-height: 16px;
    margin-top: 12px;
  }

  .likesfolwoerdivfxls {
    display: none;
  }

  .likedfollowdivflx {
    display: none;
  }

  .likesfolwoermobilrres {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 8px;
  }

  .starrating {
    padding: 10px 7px 15px 8px;
  }

  .viewsprofileprofileblmreposnive {
    width: 100%;
    max-width: 50%;
  }

  .tavbedebieisdtldiv {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .viewsandebgagemntdivmain {
    padding: 0px;
  }

  .bmlmmaposttaddddibdivmain {
    padding: 0px;
  }
  .bklmlposprofileprofileblmreposnive {
    margin: 10px 0px 10px 0px;
  }

  .bklmlposprofileprofileblmreposniveother {
    margin: 10px 0px 0px 0px;
  }

  .viewsandebgagemntdpaddinf {
    padding: 0px 0px;
  }

  .uploadbannerdivnewmain {
    width: 100%;
    padding: 0px;
  }

  .adversytfservicexedib {
    padding: 14px 0px 14px 0px;
  }

  .servicdesdetatcxttxpp {
    font: 400 14px / 18px Roboto;
  }

  .meettscysttxtpp {
    font: 500 10px / 13px Roboto;
    color: #000;
    margin-bottom: 3px;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

  .wehelpsppeottxpp {
    font: 400 8px / 12px Roboto;
    color: #000;
    margin-bottom: 7px;
  }

  .trtysttxnowtrtysttxndiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
  }

  .ipyysffraedtn {
    background-color: #089f29;
    color: #fff;
    font-size: 10px;
    padding: 4px 10px;
  }

  .likesfolwoermobilrres {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 22px 8px;
  }

  .contaallprofileblmreposnive {
    margin-top: 10px;
  }

  .reposivecideforspecial {
    display: none;
  }
}

@media (max-width: 480px) {
  .rating_modal_div{
    width: 100%;
    max-width: 85%;
    min-width: 85%;
  }
  .right_box_div{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .all_actives_wrapper h2 {
    font-family: Nunito;
    font-size: 12px;
    font-weight: 700;
}
.select_boxes select {
  font-size: 10px;
}
.enquire_div_1 p {
  font-size: 10px;
}
.enquire_div_1 h3 {
  font-size: 20px;
}
  .busineeheadpara {
    width: 100%;
    max-width: 250px;
    min-width: 250px;
    font-size: 19px;
    line-height: 24px;
  }

  .businesssubparaparasecrtstt {
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    width: 100%;
    max-width: 409px;
    padding-top: 5px;
  }

  .freebusinessbtndibmaoin {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 6px 0px;
  }

  .bannerloginbgcontent {
    left: 32%;
    transform: translate(-30%, -50%);
    width: 100%;
    padding: 25px;
  }

  .freebusinessbtn {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 8px 14px;
    font: 400 13px / 18px Roboto;
    background-color: transparent;
    color: #fff;
  }

  .bg-whtdivnmanbdivmaimdivfexrt {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-bottom: 25px;
  }

  .Promotetxtpp {
    font: 500 17px / 19px Roboto;
    height: 25px;
  }

  .WhatmakesNeoosearchtxtp {
    font: 400 15px / 22px Roboto;
    padding: 12px 0px;
  }

  .bg-whtdivnmanb {
    width: 100%;
    background-color: rgba(250, 250, 250, 1);
    height: 100%;
    margin-top: 20px;
    padding: 30px 22px;
  }

  .makednekleosearchtxtpp {
    font: 500 21px / 27px Roboto;
  }

  .businesssubparapara {
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    padding-top: 10px;
  }

  .bannerloginbg {
    width: 100%;
    height: 171px;
    position: relative;
  }

  .embermastersectionbgbobgmai {
    width: 100%;
    max-width: 48%;
    min-width: 45%;
  }

  .fallsebblldivmaindivflxl {
    flex-wrap: wrap;
  }

  .membermastersectionbgbottomdivflx {
    column-gap: 10px;
  }

  .membermastersectionbgbottom {
    width: 100%;
    padding: 20px 15px;
  }

  .alkantsrepposttxtpp {
    font: 400 12px / 16px Roboto;
    color: #000;
  }

  .alkantsrepposttxtppqueiytettcpp {
    font: 400 12px / 16px Roboto;
    color: #000;
  }

  .embermastersectionbgbobgmainnbg {
    width: 100%;
    padding: 32px 7px;
    column-gap: 8px;
    justify-content: center;
  }

  .beaiuttisprofuductimh {
    width: 100%;
    max-width: 100%;
    height: 220px;
    padding: 20px 0px;
  }

  .commsoprpodttctxppp {
    font: 400 12px / 16px Roboto;
    color: #000;
    margin: 10px 0px 5px 0px;
  }

  .ownseacrgresultpagedivflx {
    flex-wrap: wrap;
  }

  .producytppostcommnentdiv {
    padding: 10px 10px 10px 10px;
  }

  .produtitleimggsdescrdivnewallada {
    padding: 30px 20px 10px 20px;
    border-bottom: 1px solid #aba1a1;
  }

  .ownseacrgresultpageleft {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .ownseacrgresultpageright {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .fallsebblldivmain {
    width: 100%;
    min-width: 46%;
    max-width: 46%;
    column-gap: 10px;
    row-gap: 10px;
    padding-bottom: 18px;
  }

  .ownseacrgresultpagerreslppimg {
    width: 100%;
    height: 170px;
  }

  .fallsebblldivmaindivflxl {
    padding: 20px 0px 10px 0px;
  }

  .serachresultdetailsdiv {
    width: 100%;
    max-width: 90%;
    min-width: 90%;
  }

  .serctedheadrparatxt {
    font: 400 12px / 20px Roboto;
  }

  .allpostimagedesuigfnatinob {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: flex-start;
  }

  .fallsebblldivmain:last-child {
    padding-bottom: 0px;
  }

  .serchrelutparatxtmm {
    font: 400 12px / 20px Roboto;
  }

  /* .uploadimagedivflx {
        margin: 38px 0px 0px 0px;
    } */
  .servicdesdetatcxttxpp {
    font: 400 14px / 18px Roboto;
  }

  .chooseplandivmaondivbrdr {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .serachresultlogoidiv {
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    height: 30px;
  }

  .busuyyasbasytxtxtppli {
    font: 400 17px / 20px Roboto;
  }

  .uploadbannerdivbg {
    height: 200px;
  }

  .choosttappanbtn {
    width: 100%;
    max-width: 140px;
    min-width: 140px;
    font-size: 16px;
  }

  .buiisconnesttxtppp {
    font: 500 15px / 19px Roboto;
    color: #000;
  }

  .sennjandrecetxtpp {
    font: 400 14px / 16px Roboto;
    color: #000;
  }

  .choosplantxdivmain {
    padding-top: 5px;
  }

  .freebusinessbtn {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 8px 10px;
    font: 400 13px / 16px Roboto;
    background-color: transparent;
    color: #fff;
  }

  .busineeheadparadirecrpara {
    color: #fff;
    font-size: 17px;
    line-height: 21px;
    font-family: Roboto;
    width: 100%;
    max-width: 633px;
    font-weight: 600;
  }

  .eidteprofileblmreposnive {
    width: 100%;
    max-width: 55%;
  }

  .viewsprofileprofileblmreposnive {
    width: 100%;
    max-width: 45%;
  }
  .all_actives_wrapper{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
  }
  .select_boxes{
   width: 100%;
   max-width: 100%;
   min-width: 100%;
   justify-content: center;
  }
  .select_boxes select{
    width: 100%;
    max-width: 32%;
    min-width: 32%;
  }
}

@media (max-width: 420px) {
  .overview_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
        padding: 15px 15px 15px 15px;
  }
  
  .agereecheck {
    padding-bottom: 16px;
    padding-top: 10px;
    align-items: flex-start;
  }

  .consttactdetatlsarroedyrdiv {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .editeiconimg {
    width: 100%;
    max-width: 17px;
    min-width: 17px;
    height: 17px;
    margin-left: auto;
  }

  .agreerxtppptt {
    font-size: 11px;
  }

  .freebusinessbtn {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 8px 7px;
    font: 400 10px / 18px Roboto;
    background-color: transparent;
    color: #fff;
  }

  .servicdesdetatcxttxpp {
    font: 400 12px / 14px Roboto;
  }

  .listbusinesstxtpp {
    color: #000;
    font-size: 17px;
    line-height: 22px;
  }

  .businesssubparapara {
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    padding-top: 4px;
  }

  .searchfilterdiv {
    width: 100%;
    max-width: 12%;
    min-width: 12%;
  }

  .Searchloginformmainrightbg {
    padding: 25px 20px;
    width: 100%;
    max-width: 100%;
  }

  .addesdwqtssxtoipnt {
    width: 100%;
    max-width: 70px;
    min-width: 70px;
    height: 34px;
  }

  .neeporoalposttsheadr {
    font: 500 13px / 16px Roboto;
    color: #1d1d1d;
    margin-bottom: 5px;
  }

  .allpostimagedesuigfnatinob {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
  }

  .postimagedesrcptoioponttxp {
    font: 400 11px / 13px Roboto;

    margin-bottom: 5px;
  }

  .poosttalouindtdettstctpopdoiv {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
  }

  .uploadbannerdivnewmain {
    width: 100%;
    padding: 10px;
  }

  .produucvtsdlikeinmncomnfdivvhjjd {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    column-gap: 6px;
    cursor: pointer;
  }

  .likkskjsbtcomnbvtyoipuxtt {
    font: 400 12px / 15px Roboto;
    color: #000000;
  }

  .servicdesdetatcxttxpp {
    font: 400 14px / 18px Roboto;
  }

  .btnnexttdivbtn {
    background-color: #09a72c;
    color: #fff;
    font-size: 12px;
    padding: 4px 20px;
  }

  .mobilereposvispsicaldivflx {
    flex-wrap: wrap;
  }

  .eidteprofileblmreposnive {
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
  }

  .viewsprofileprofileblmreposnive {
    width: 100%;
    max-width: 100%;
    margin: 10px 0px;
  }

  .likesfolwoermobilrres {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 40px;
  }

  .contaallprofileblmreposnive {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .meettscysttxtpp {
    font: 500 13px / 18px Roboto;
    color: #000;
    margin-bottom: 3px;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

  .wehelpsppeottxpp {
    font: 400 11px / 16px Roboto;
    color: #000;
    margin-bottom: 7px;
  }

  .ipyysffraedtn {
    background-color: #089f29;
    color: #fff;
    font-size: 11px;
    padding: 6px 10px;
  }

  .mobilereposvispsicaldivflx {
    margin: 0px 0px;
  }
}
button:disabled{
  background-color: #b0beb9;
  cursor: not-allowed;
}

.currencySym {
  color: grey;
  font-size: 60%;
  margin: 0 5px 0 20px;
  vertical-align: top;
  line-height: 24px;
  text-decoration: none !important;
}
.colored-strike {
  position: relative;
  color: black; /* Original text color */
}
.colored-strike::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px; /* Thickness of the line */
  background-color: red; /* Color of the line */
  transform: translateY(-50%);
}

.search-result-view {
  display: flex;
}
.search-result-view button {
  /* width: 100%;
  max-width: 140px;
  min-width: 140px; */
  padding: 6px 16px;
  color: #333;
  border: 1px solid #b4b4b4;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: transparent;
  font: 400 12px / 16px Roboto;
  /* font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  height: 41px; */
}
.search-result-view button:hover {
  background: #fff;
}

.rating-value {
  font: 500 20px/24px Roboto;
  color: #000;
  /* display: block;
  margin-bottom: 10px; */
}
.rating-value::after {
  content: "";
  display: block;
  height: 10px; 
}
