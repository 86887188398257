.homepageHeader {
    width: 100%;
    height: auto;
    padding: 30px 0px;
    border-bottom: 1px solid #B6A8A8;
    background-color: #662D91;
}

.homeHeadInn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.homeMenu {
    display: flex;
    gap: 40px;
}

.homeJoinBtn {
    height: 45px;
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    color: #FBF0F0;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
    /* background: linear-gradient(to left, #6A08B7 50%, rgb(139 158 209) 50%) right; */
    background: transparent;
    border: 1px solid #FBF0F0;
    background-size: 200%;
    transition: .5s ease-out;
}

.homeJoinBtn:hover {
    /* background-position: left; */
    background: #fff;
    color: #662D91;
}

.homeactvBtn {
    height: 45px;
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border-radius: 16px;
    border: 1px solid #fff;
    background: #FFF;
    color: #662D91;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
    /* background: linear-gradient(to left, #fff 50%, rgb(139 158 209) 50%) right; */
    background: #fff;
    background-size: 200%;
    transition: .5s ease-out;
}

.homeactvBtn:hover {
    /* background-position: left; */
    background: transparent;
    color: #FBF0F0;
}

.homehamberger_btn {
    text-decoration: none;
    box-shadow: none;
    border: none;
    background-color: transparent;
    outline: 0;
    font-size: 30px;
    /* color: #662D91; */
    color: #fff;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.homeBtm {
    width: 100%;
    height: auto;
    padding-top: 30px;
    position: relative;
}

.homeDiv {
    display: flex;
    justify-content: space-between;
}

.homeLeft {
    width: 60%;
}

.Homefig {
    position: absolute;
    right: 0;
    max-width: 420px;
    width: 40%;
    height: 500px;
}

.Homefig img {
    width: 100%;
    height: 100%;
}

.homeContent {
    width: 60%;
}

.homebighead {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.2px;
    margin-bottom: 30px;
}

.homeSmallHead {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.homepara {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
}

.homepara a {
    font-size: 12px;
    color: #007bff;
    margin-left: 10px;
    text-decoration: none;
    box-shadow: none;
}

.homeForm {
    padding-top: 40px;
    width: 70%;
}

.homeformHead {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.75px;
    margin-bottom: 20px;
}

.homeForm input {
    width: 100%;
    height: 40px;
    border-radius: 12px;
    background: #D9D9D9;
    color: #4B4949;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.45px;
    border: none;
    outline: 0;
    padding: 3px 15px;
}

.homeForm input:-webkit-autofill,
.homeForm input:-webkit-autofill:hover,
.homeForm input:-webkit-autofill:focus,
.homeForm input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.homeForm input[type='checkbox'] {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    accent-color: #6A08B7;
}

.HomeFormFlex {
    display: flex;
    justify-content: space-between;
    color: #777;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.39px;
}

.HomefrgtPwd {
    color: #777;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.39px;
}

.HomefrgtPwd:hover {
    color: #777;
}

.passDiv {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background: #D9D9D9;
}

.passEyebtn {
    border: none;
    outline: 0;
    background-color: transparent;
    padding-right: 15px;
}

.passEyebtn i {
    color: #151515;
    font-size: 16px;
}

.homeloginBtn {
    width: 100px;
    height: 40px;
    border: none;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #009E60;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    transition: all 0.5s ease;
}

.homeloginBtn:hover {
    color: #009E60;
    border: 1px solid #009E60;
    background-color: #FFF;
}

.homeStruc {
    position: absolute;
    right: -200px;
    height: auto;
}

.homeStrucInn {
    background: #0052A4;
    opacity: 0.22;
    width: 550px;
    height: 500px;
    border-radius: 50%;
}

/* .HomeFigOne {
    position: absolute;
    left: -40px;
    top: 252px;
    width: 280px;
    height: auto;
    z-index: 3;
} */

.HomeFigOne {
    position: absolute;
    left: -158px;
    top: 149px;
    width: 280px;
    height: auto;
    z-index: 3;
    rotate: -12deg;
}

/* .HomeFigTwo {
    position: absolute;
    left: -40px;
    top: 55px;
    width: 280px;
    height: auto;
    z-index: 2;
} */

.HomeFigTwo {
    position: absolute;
    left: 12%;
    top: 40px;
    width: 230px;
    height: auto;
    z-index: 2;
}

.HomeFigThree {
    position: absolute;
    left: 28px;
    top: 50px;
    width: 320px;
    height: auto;
    z-index: 1;
}

.homimg img {
    width: 100%;
    height: 100%;
}


.landingpage_allbtn {
    width: 100%;
    height: auto;
    padding: 90px 0;
    display: flex;
    align-items: center;
    justify-content: start;
}

.landingExplore_btn {
    width: 100%;
    max-width: 100px;
    height: 40px;
    border: 1px solid #662D91;
    padding: 5px 15px;
    border-radius: 12px;
    background: transparent;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #662D91;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    cursor: pointer;
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    margin-left: 30px;
}

.landingExplore_btn:hover {
    background: #662D91;
    color: #fff;
}

.home_modal_wrapper{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.home_modal_div{
    width: 100%;
    max-width: 40%;
    min-width: 40%;
    background-color: white;
    padding: 30px 0px;
    border-radius: 10px;
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}
.error_div{
    width: 100%;
    max-width: 70px;
    min-width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-inline: auto;
  
}
.error_div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.home_modal_div h2{
    font-size: 25px;
    text-align: center;
    margin-top: 10px;
}
.home_modal_div h4{
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
    font-weight: 700;
}
.home_modal_div h6{
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
}
.home_modal_div button{
    width: 120px;
    height: 35px;
    border-radius: 8px;
    color: white;
    border: none;
    background-color: #009E60;
    margin-top: 20px;
}
/* Responsive */

@media (max-width: 1399px) {
    .homepageHeader {
        padding: 15px 0px;
    }

    .homeJoinBtn,
    .homeactvBtn {
        height: 44px;
        padding: 5px 25px;
        font-size: 16px;
    }

    .homeMenu {
        gap: 20px;
    }
}

@media (max-width: 1199px) {
    .home_modal_div{
        width: 100%;
        max-width: 60%;
        min-width: 60%;
    }
    .homeBtm {
        height: 100vh;
    }

    .homeLeft {
        width: 50%;
    }

    .homeContent {
        width: 100%;
    }

    .homeForm {
        padding-top: 40px;
        width: 100%;
    }

    .homebighead {
        font-size: 32px;
        margin-bottom: 10px;
    }

    .homeSmallHead {
        font-size: 16px;
    }

    .homeForm {
        padding-top: 25px;
    }

    .Homefig {
        max-width: 350px;
        width: 40%;
        height: 400px;
    }

    .homeformHead {
        font-size: 22px;
    }
}

@media (max-width: 991px) {
    .home_modal_div{
        width: 100%;
        max-width: 70%;
        min-width: 70%;
    }
    .homeStrucInn {
        width: 450px;
        height: 400px;
    }

    .HomeFigOne {
        width: 200px;
        top: 195px;
    }

    .HomeFigThree {
        left: 0px;
        top: 40px;
        width: 250px;
    }

    .HomeFigTwo {
        width: 200px;
    }

    .homebighead {
        font-size: 25px;
    }

    .homeSmallHead {
        font-size: 14px;
    }

    .homepara {
        font-size: 13px;
    }

    .homeformHead {
        font-size: 20px;
    }
}

@media (max-width: 767px) {

    /* .homeStruc{
        display: none;
    } */
    .home_modal_div{
        width: 100%;
        max-width: 80%;
        min-width: 80%;
    }
    .home_modal_div h6 {
        font-size: 15px;
        text-align: center;
        margin-top: 10px;
    }
    .homeDiv {
        height: auto;
        flex-direction: column;
        position: static;
    }

    .Homefig {
        display: none;
    }

    .homeBtm {
        height: auto;
    }

    .homeStruc {
        position: relative;
        right: -300px;
        height: auto;
    }

    .homeLeft {
        width: 100%;
        padding-bottom: 30px;
    }

    .homebighead,
    .homeSmallHead {
        margin-bottom: 15px;
    }

    .homeloginBtn {
        width: 100px;
    }

    .landingpage_allbtn {
        padding: 50px 0;
    }
}

@media (max-width: 575px) {
    .home_modal_div{
        width: 100%;
        max-width: 90%;
        min-width: 90%;
    }
    .homeHeadInn {
        /* flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start; */
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    .homebighead {
        font-weight: 500;
        color: #8C369B;
    }

    .homeStrucInn {
        width: 400px;
        height: 350px;
    }

    .HomeFigOne {
        width: 160px;
        top: 160px;
    }

    .HomeFigTwo {
        width: 160px;
    }

    .HomeFigThree {
        width: 190px;
    }

    .homeJoinBtn,
    .homeactvBtn {
        height: 28px;
        padding: 5px 10px;
        font-size: 12px;
    }

    .homeMenu {
        gap: 12px;
    }

    .homebighead {
        font-size: 22px;
    }

    .homepara {
        font-size: 10px;
    }



    .homepara a {
        font-size: 8px;
    }

    .homeSmallHead {
        font-size: 12px;
    }

    .homeloginBtn {
        width: 120px;
        height: 35px;
        font-size: 14px;
    }

    .landingExplore_btn {
        height: 35px;
        font-size: 14px;
    }

    .landingpage_allbtn {
        padding: 30px 0;
    }
}

@media (max-width: 480px) {

    .homeStruc {
        right: -250px;
    }

    .HomeFigTwo {
        width: 140px;
        left: -25px;
    }

    .HomeFigOne {
        width: 140px;
        top: 145px;
    }

    .HomeFigThree {
        width: 165px;
    }

    .landingpage_allbtn {
        padding: 20px 0 0px;
    }

    .homehamberger_btn {
        font-size: 22px;
    }
}

@media (max-width: 420px) {
    .homeStruc {
        right: -200px;
    }

    .homeJoinBtn,
    .homeactvBtn {
        font-size: 11px;
    }
}

@media (max-width: 360px) {
    .homeStruc {
        right: -155px;
    }
}